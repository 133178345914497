import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    videoId: "",
    channelId:""
}

export const manageId = createSlice({
    name: 'manageId',
    initialState,
    reducers: {
        setVideoId: (state, action) => {
            state.videoId =  action.payload;
            localStorage.setItem("videoId",action.payload)
        },
        setChannelId:(state, action) => {
            state.channelId = action.payload
            localStorage.setItem("channelId",action.payload)
        },

    },
})

export const { setChannelId,setVideoId} = manageId.actions;
export const manageIdReducer = manageId.reducer;
