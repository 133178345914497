import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";
import '../css/components/table.scss'
import noDataImage from "../assets/nodata.svg";
import { Box } from "@mui/material";
const Table = ({
  data = [],
  column = [],
  loading = false,
  redirect = "_id",
  noDataHeading = ""
}) => {
  // states
  
  const [rowsNumber, setRowsNumber] = useState(25);
  const [page, setPage] = useState(1);
  const [showSorting, setShowSorting] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (data) {
      setList(data);
    }
  }, [data]);

  const handleSort = (order, property, type) => {
    try {
      setShowSorting(false);
      if (!list || list.length === 0) {
        return;
      }
  
      const sortedArr = [...list];
  
      sortedArr.sort((a, b) => {
        if (type === "string") {
          const valueA = a[property];
          const valueB = b[property];
          return order === "asc" ?  valueA.localeCompare(valueB) :  valueB.localeCompare(valueA);
        }
        if(type === "date"){
          const valueA = new Date(a[property]).getTime();
          const valueB = new Date(b[property]).getTime();
         return order === "asc" ?  valueA - valueB : valueB -valueA ;
        }
        return 0; 
      });

      
      setList(sortedArr);
    } catch (error) {
      console.error("Error sorting list:", error);
    }
  };
  


  const handlePrevPage = () => {
    if (page <= 1) {
      return;
    } else {
      setPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    const maxPageNumber = Math.ceil(Number(list.length) / rowsNumber);
    if (page >= maxPageNumber) {
      return;
    } else {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <div className="table-container">
      {
        list.length > 0 && <table className="main-table">
        <thead>
          <tr>
            <th>S.No</th>
            {column.map((val, i) => {
              return val.sorting ? (
                <th style={{
                  minWidth:`${val.minWidth}px`
                }} key={i} className="sorting-row">
                  {val?.headerName}
                  <AiFillCaretDown
                    style={{
                      transform: showSorting ? "rotate(180deg)" : "",

                      transition: "0.3s",
                    }}
                    onClick={() => setShowSorting(!showSorting)}
                  />
                  <div
                    className="sorting-option"
                    style={{
                      height: showSorting ? "100px" : "0px",
                    }}
                  >
                    <li
                      key={"asc"}
                      onClick={() => handleSort("asc", val?.property, val?.type)}
                    >
                      {" "}
                      Sort A- Z
                    </li>
                    <li
                      key={"dsc"}
                      onClick={() => handleSort("desc",val?.property, val?.type)}
                    >
                      Sort Z- A
                    </li>
                  </div>
                </th>
              ) : (
                <th style={{
                  minWidth:`${val.minWidth}px`
                }} key={i}>{val?.headerName}</th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {list && list
            ?.slice(
              rowsNumber * (page - 1),
              rowsNumber * (page - 1) + rowsNumber
            )
            .map((val, i) => (
              <tr key={i}>
                <td>{rowsNumber * (page - 1) + i + 1}</td>
                {column.map((content, i) => (
                  <td key={i}>
                    {content.type === "image" ? (
                      <div className="user-pic">
                        {val.dp ? (
                          <img src={val.dp} alt="" />
                        ) : (
                          <img src="/images/profile.jpg" alt="" />
                        )}
                      </div>
                    ) : (
                      <span>{val[content.property]}</span>
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      }

      {/* No data image */}
      {!loading && list.length === 0 && (
        <div className="no-data">
          <Box p={2} bgcolor={"white"} borderRadius={1} boxShadow={"0 3px 16px rgba(110, 125, 177, 0.28)"}>
          <h2>{noDataHeading || "Sorry ! No Data Available"}</h2>
          </Box>
          <img
          src={noDataImage}
          alt="no data"
        />
        </div>
        
    
    )}

      {/* Pagination */}
      <div style={{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        marginTop:"40px",
        gap:"5px",
        
      }}>
        <div className="rows-div">
          <select
            name="rowsNumber"
            onChange={(e) => setRowsNumber(Number(e.target.value))}
            style={{
              width: "60px",
              height: "30px",
              marginRight: "20px",
            }}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div className="page-number-div">
          <button className="btn-primary" onClick={handlePrevPage}>
            Prev
          </button>
          {page} of {data?.length === 0 ? 1 : Math.ceil(Number(data?.length) / rowsNumber)}
          <button className="btn-primary" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
