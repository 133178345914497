import React from 'react'
import '../../css/sidebar/sidebar.scss'
import {  useLocation, useNavigate } from 'react-router-dom'
import { topSideBarLinks } from './upperLinks';
import { dashboardSideBarLinks } from './upperLinks';


const Sidebar = () => {
    const navigate = useNavigate()
    const{pathname} = useLocation();
  return (
    
        <div className="sidebar-container">
            <div className="logo-div">
                <h1>AFFLIATE</h1>
            </div>
            <div className="sidebar-links">
                {topSideBarLinks.map((val,i)=>(
                    <div className={val.link === pathname ? "link-div-active" : "link-div"} onClick={()=>navigate(val.link)}>
                        {val.icon}
                        {val.title}
                    </div>
                ))}
            </div>
            
            <div className="sidebar-links">
            <div className="dashboard">
                <h3>Dashboard</h3>
            </div>
            <br />
                {dashboardSideBarLinks.map((val,i)=>(
                    <div className={val.link === pathname ? "link-div-active" : "link-div"}  onClick={()=>navigate(val.link)}>
                        {val.icon}
                        {val.title}
                    </div>
                ))}
            </div>
        </div>
        
  
  )
}

export default Sidebar