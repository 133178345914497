import React from 'react'
import '../../css/channel/channel.scss'
import OfferImage from '../../assets/offer.webp'

const PackageCard = ({packageTitle,dicountPrice,totalAmount,buyPackage}) => {
  return (
    <div className='package-card'>
<img src={OfferImage}  alt=""  style={{
    borderRadius:"12px",
    height:"100%",
    width:"200px"
}} />

<div className="package-info">
    <h3>{packageTitle}</h3>
    <h1>{dicountPrice}</h1>
    <delete>{totalAmount}</delete>
    <button className='btn-primary' onClick={()=>buyPackage(packageTitle)} >Buy Package</button>
</div>

    </div>
  )
}

export default PackageCard