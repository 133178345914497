import React, { useEffect } from "react";
import '../../css/profile/profile.scss'
import {useDispatch, useSelector} from 'react-redux'
import { myProfileAction } from "../../redux/auth/myProfile";

const Profile = () => {
  const dispatch = useDispatch()
  const {data} = useSelector(state=>state.getProfile)
  useEffect(()=>{
    dispatch(myProfileAction())
  },[])
  return (
    <div className="body-container center-profile">
      <div className="profile-container">
       
        <div className="user-profile-div">
        <label htmlFor="name">Full Name</label>
          <input type="text" value={data?.name} disabled className="user-profile-input" />
        </div>
        <div className="user-profile-div">
        <label htmlFor="email">E-mail</label>
          <input type="text" disabled value={data?.email} className="user-profile-input" />
        </div>
        <div className="user-profile-div">
        <label htmlFor="name">Phone Number</label>
          <input type="number" name=""  placeholder="Enter Your Phone No.." className="user-profile-input"/>
        </div>
        <button className="btn-secondary">Verify Phone Number</button>
      </div>
    </div>
  );
};

export default Profile;
