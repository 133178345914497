import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

export default function FAQ({ data }) {
  return (
    <div>
      {data?.map((val, i) => (
        <Accordion elevation={0} sx={{
      
        }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <h4
              style={{
                fontWeight: 510,
              }}
            >
              {val?.question}
            </h4>
          </AccordionSummary>
          <AccordionDetails>
           {val?.answer}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
