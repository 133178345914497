import React from "react";
import '../../css/channel/channel.scss'
import PackageCard from "./PackageCard";
import {motion} from 'framer-motion'
import {useNavigate} from 'react-router-dom'
const packageDetails =[{
title:"YouTube Monetization Package",
dicountPrice:"$999",
totalAmount:"$1491"
}]
const Packages = () => {
  const navigate = useNavigate();

  const handleBuyPackage =(p)=>{
    if(p.toString().toLowerCase().includes("monetization package"))
    navigate('/channel-monetize')
  }
  return (
    <motion.div 
    initial={{
        opacity: 0,
        transform: "scale(0.9)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        duration: 0.2,
      }}
    className="body-container">
        <br />
      <h2>Select From Packages Below </h2>
      <div className="package-container">
        {packageDetails.map((val)=><PackageCard packageTitle={val.title} buyPackage={handleBuyPackage} dicountPrice={val.dicountPrice} totalAmount={val.totalAmount} />)}
      </div>
    </motion.div>
  );
};

export default Packages;
