
import axios from "axios";
 const authAxios = axios.create({
  // baseURL: "http://localhost:4000/api/v1",
  baseURL: 'https://backend.proaffiliate.io/api/v1',
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

authAxios.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    let {
      response: {
        data: { status,message },
      },
    } = error;
    if(message === "jwt malformed"){
      // window.alert("Your Session Expired")
      // localStorage.clear()
      // window.location.href = '/'
    }
    return Promise.reject(error);
  }
);

export default authAxios;

