import React, { useEffect, useState } from "react";
import DropMenu from "../components/DropMenu";
import "../css/components/upperheader.scss";
import { IoMdWallet } from "react-icons/io";
import { FcOnlineSupport } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllChannelsAction } from "../redux/channel/getAllChannel";
import ProfileImage from "../assets/profile.png";
import { myProfileAction } from "../redux/auth/myProfile";
import { numberFormatter } from "../helper/numberFormatter";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MobileSidebar from "../screens/sidebar/MobileSidebar";



const UpperHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.getAllChannels);
  const { data: profileData } = useSelector((state) => state.getProfile);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllChannelsAction());
    dispatch(myProfileAction());
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        left: "0px",
        top: "0px",
        height: "80px",
        background: "linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)",
        zIndex: 10000,
      }}
    >
    
       <div className="hamburger-icon">
        <MobileSidebar/>
       </div>

      {/* <DropMenu list={data} /> */}
      <div className="profile-div">
        <div className="support">
          <FcOnlineSupport />
        </div>

        <div className="wallet-balance" onClick={() => navigate("/add-funds")}>
          <h4>₹ {numberFormatter(profileData?.balance) || 0}</h4>
          <IoMdWallet />
        </div>

        <h4 className="user-name-headline">{profileData?.name?.toUpperCase()}</h4>
        <div
          className="profile-img"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <img
            src={ProfileImage}
            alt="profile"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <Menu
          style={{
            marginTop: "60px",
            zIndex: 10000,
          }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/my-profile");
              setAnchorEl(!anchorEl);
            }}
          >
            My Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
              setAnchorEl(!anchorEl);
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default UpperHeader;
