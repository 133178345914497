import React from 'react'
import '../css/components/transparentloader.scss'
const TransparentLoader = () => {
  return (
    <div className='transparent-loader-body'>
       <div class="dot-spinner">
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
    <div class="dot-spinner__dot"></div>
</div> 
    </div>
  )
}

export default TransparentLoader