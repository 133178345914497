import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    countryList: []
}

export const getCountryListSlice = createSlice({
    name: 'countryList',
    initialState,
    reducers: {
        selectCountry: (state, action) => {
            if(state.countryList.includes(action.payload)){
                return
            }
            state.countryList.push(action.payload);
        },
        removeCountry:(state, action) => {
            const index = state.countryList.indexOf(action.payload);
            state.countryList.splice(index,1)
        },

        resetCountry:(state)=>{
            state.countryList = []
        }
    },
})

export const { selectCountry,removeCountry,resetCountry } = getCountryListSlice.actions;
export const getCountryListReducer = getCountryListSlice.reducer;
