import React, { useState } from 'react'
import {motion} from 'framer-motion'
import Table from '../../components/Table'

const Report = () => {
    const[data,setData] = useState([])
    const column = [
      {
        headerName: "VIDEO",
        type: "string",
        property: "name",
      },
      {
        headerName: "TITLE",
        type: "string",
        property: "name",
      },
      {
        headerName: "STATUS",
        type: "string",
        property: "name",
      },
      {
        headerName: "BUDGET",
        type: "string",
        property: "name",
      },
      {
        headerName: "VIEWS DELIVERED",
        type: "string",
        property: "name",
      },
      {
        headerName: "SPENT",
        type: "string",
        property: "name",
      }
    ];
  return (
    <motion.div
    className='body-container'
    initial={{
        opacity: 0,
        transform: "scale(0.9)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        duration: 0.2,
      }}
    >
        <Table column={column} data={data}/>
    </motion.div>
  )
}

export default Report