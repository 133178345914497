import React from "react";
import { motion } from "framer-motion";
import "../../css/affiliate/affiliate.scss";
import AffiliateLeftImage from '../../assets/affiliate.svg'
import CommisionAffiliate from '../../assets/affiliateCommision.svg'
import RegisterAffiliate from '../../assets/registerAffiliate.svg'
import SharerAffiliate from '../../assets/shareAffiliate.svg'
const Affiliate = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        transform: "scale(0.9)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        duration: 0.2,
      }}
      className="body-container center-affiliate"
    >
      <div className="affiliate-upper-div">
   
        <h1>
          Join Our Affiliate Program
        </h1>
  
        <button
          className="btn-primary"
          style={{
            width: "100px",
          }}
        >
          Join Now
        </button>
     
       
       
        <img className="affilate-program-img" src={AffiliateLeftImage} alt="affilate"  />
      </div>

      <div className="affiliate-process-container">
        <div className="affiliate-card-1">
          <img src={RegisterAffiliate} alt="commision" />
            <h3>First get yourself registered,</h3>
        </div>
        <div className="right-partition-affiliate">
        <div className="affiliate-card">
          <img src={SharerAffiliate} alt="share"  />
            <h3> Then share to your friends</h3>
        </div>
        <div className="affiliate-card">
          <img src={CommisionAffiliate} alt="" />
            <h3> And earn 15% commission.</h3>
        </div>
        </div>
        
      </div>
    </motion.div>
  );
};

export default Affiliate;
