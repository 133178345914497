import React from "react";
import SupportImage from "../../assets/ticket.svg";
import { motion } from "framer-motion";
import "../../css/ticket/ticket.scss";
const Ticket = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        transform: "scale(1.1)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        duration: 0.2,
      }}
      className="body-container"
    >
      <div className="ticket-body">
        <img className="support-image-ticket" src={SupportImage} alt="ticket" />

        <h1>
          Please contact us at <strong>support@proaffiliate.com </strong> for
          any kind of assistance. 🚀
        </h1>
        <button
          className="btn-primary"
          style={{
            width: "120px",
          }}
        >
          Contact Us
        </button>
      </div>
    </motion.div>
  );
};

export default Ticket;
