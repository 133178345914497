import { SlSocialYoutube } from "react-icons/sl";
import { MdAlternateEmail } from "react-icons/md";
import { GoPackage } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { MdLocalOffer } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";
import { MdPayment } from "react-icons/md";
import { RiFundsFill } from "react-icons/ri";
import { IoTicketSharp } from "react-icons/io5";
import { FaMedal } from "react-icons/fa";
import { FaGift } from "react-icons/fa6";
import { TfiSharethisAlt } from "react-icons/tfi";
export const topSideBarLinks =[
    {
        title:"Promote A Video",
        link:"/list-video",
        icon:<SlSocialYoutube/>
    },
    // {
    //     title:"Promote A Channel",
    //     link:"/promote-channel",
    //     icon:<MdAlternateEmail/>
    // },
    {
        title:"Monetize Channel",
        link:"/channel-monetize",
        icon:<GoPackage/>
    }
]

export const dashboardSideBarLinks = [{
    title:"My Campaigns",
    link:"/my-campaigns",
    icon:<FaPlay/>
},
// {
//     title:"Packages",
//     link:"/my-packages",
//     icon:<MdLocalOffer/>
// },
// {
//     title:"Channel",
//     link:"/my-channels",
//     icon:<FaYoutube/>
// },
// {
//     title:"Report",
//     link:"/reports",
//     icon:<BiSolidReport/>
// },
{
    title:"Payment Spends",
    link:"/payments",
    icon:<MdPayment/>
},
{
    title:"Add Funds",
    link:"/add-funds",
    icon:<RiFundsFill/>
},
{
    title:"Ticket",
    link:"/tickets",
    icon:<IoTicketSharp/>
},
{
    title:"Affiliates",
    link:"/affiliate",
    icon:<FaMedal/>
},
// {
//     title:"Free Views",
//     link:"/start",
//     icon:<FaGift/>
// },

// {
//     title:"Refferals",
//     link:"/select-video",
//     icon:<TfiSharethisAlt/>
// },

]