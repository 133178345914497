import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import "../../css/add-fund/addfund.scss";
import AddUrlInput from "../promote-video/AddUrlInput";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentTransactionAction } from "../../redux/payment/getPaymentTransaction";
import Loader from "../../components/Loader";
import { Toast } from "../../components/Toast";
import { addBalanceAction } from "../../redux/payment/addBalance";
import ShowAddFundTransaction from "./ShowAddFundTransaction";
import Alert from "@mui/material/Alert";
import { Box } from "@mui/material";
import Modal from "../../components/Modal";
import { getSinglePaymentTransactionAction } from "../../redux/payment/getSingleTransaction";
import { MdError } from "react-icons/md";
import { MdVerified } from "react-icons/md";
import { myProfileAction } from "../../redux/auth/myProfile";

function AddFunds() {
  const [amount, setAmount] = useState(null);
  const [snake, setSnake] = useState(false);
  const[openPaymentModal,setOpenPaymentModal] = useState(false)
  const [paymentStatus,setPaymentStatus] = useState("")
  const [flagPaymentPop,setFlagPaymentPop] = useState(true);

  // redux
  const { data, loading } = useSelector((state) => state.paymentTransaction);
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState(window.screen.availWidth);
  const { data: profileData } = useSelector((state) => state.getProfile);
  const {
    loading: addBalanceLoading,
    message,
    status,
  } = useSelector((state) => state.addBalance);

  // local storage
  const transactionId = localStorage.getItem("transactionId")

  // fetch trans
  useEffect(() => {
    dispatch(getPaymentTransactionAction());
    dispatch(myProfileAction())
  }, []);

  // get payment status pop
  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        if (transactionId) {
          const getPaymentStatus = await dispatch(getSinglePaymentTransactionAction({ transactionId }));
          if (getPaymentStatus?.payload?.status === 200) {
            localStorage.removeItem("transactionId")
            setOpenPaymentModal(true);
            setPaymentStatus(getPaymentStatus?.payload?.data?.status);

          }
        }
      } catch (error) {
        console.error('Error fetching payment status:', error);
       
      }
    };

    if(transactionId && flagPaymentPop){
      fetchPaymentStatus(); 
    }
  }, [transactionId,flagPaymentPop]);
  
  // handle mobile view
  const handleWindowSize = () => {
    setWindowSize(window.screen.availWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSize);
    return () => {
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);


  // add payment
  const handleAddBalance = async () => {
    if (amount <= 0) {
      return;
    }
    const addAmount = await dispatch(addBalanceAction({ amount: amount }));
    setSnake(true);
    setFlagPaymentPop(false);
    if (
      addAmount?.payload?.status === 200 &&
      addAmount?.payload?.type === "paymentGateway"
    ) {
      const data = addAmount?.payload?.data?.data;
      window.history.replaceState(null, '', window.location.href);
      localStorage.setItem("transactionId",addAmount?.payload?.transactionId)
      window.location.href = data?.instrumentResponse?.redirectInfo?.url
    }
  };

  // column options
  const column = [
    {
      headerName: "TRANSACTION ID",
      type: "string",
      property: "merchantTransactionId",
      minWidth: 100,
    },
    {
      headerName: "AMOUNT",
      type: "string",
      property: "amount",
      minWidth: 140,
    },

    {
      headerName: "Status",
      type: "string",
      property: "status",
      minWidth: 100,
    },
    {
      headerName: "Type",
      type: "string",
      property: "type",
      minWidth: 100,
    },
    {
      headerName: "Wallet Ded.",
      type: "string",
      property: "walletDeductedAmount",
      minWidth: 100,
    },
    {
      headerName: "DATE",
      type: "date",
      property: "timestamp",
      minWidth: 300,
      sorting: true,
    },
  ];

  //  amount option

  const amountOption = [
    { name: "₹ 100", value: 100 },
    { name: " ₹ 200", value: 200 },
    { name: "₹ 300", value: 300 },
    { name: "₹ 400", value: 400 },
  ];
 
  return (
    <>
      <div className="body-container">
        <div className="add-fund-container">
          <div className="wallet-balance">
            <h4>Wallet Balance</h4>
            <h2>₹ {Number(profileData?.balance || 0).toFixed(2)}</h2>
          </div>
          <div className="add-amount">
            <h4>Add amount to wallet</h4>
            <AddUrlInput
              minWidth="50vw"
              button={false}
              placeholder={"Enter The Amount"}
              icon={"payment"}
              type="number"
              value={amount}
              onChange={(e)=>setAmount(e.target.value)}
            />
            <div className="amount-option">
              {amountOption.map((val, i) => (
                <button
                  className="btn-primary btn-add-fund-option"
                  key={i}
                  onClick={() => setAmount(val.value)}
                >
                  {val.name}
                </button>
              ))}
              <button
                onClick={handleAddBalance}
                disabled={addBalanceLoading}
                className="btn-add-money"
              >
                Add Amount
              </button>
            </div>
          </div>
        </div>
        <br />
        <Alert
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
          className="alert-campaign"
          severity="warning"
        >
          Do not worry, your wallet amount will be refunded if your payment has
          been failed.
        </Alert>
        {windowSize < 500 ? (
          <Box bgcolor={"white"} p={1} width={"100%"} borderRadius={2} mt={2}>
            {" "}
            <ShowAddFundTransaction data={data} />
          </Box>
        ) : (
          <Table noDataHeading="No Payment Available" column={column} data={data} />
        )}
      </div>

      {/* other comp */}

      <Modal open={openPaymentModal} setopen={setOpenPaymentModal} icon={paymentStatus === "Success" ? <MdVerified style={{color:"green", fontSize:"2rem"}}/> : <MdError style={{color:"red", fontSize:"2rem"}}/>} header={`Payment ${paymentStatus}`} content={paymentStatus === "Success" ? "Payment Recieved Successfully" : "Payment Not Received"}/>
      {loading && <Loader />}
      {addBalanceLoading && <Loader />}
      <Toast
        message={message}
        status={status}
        open={snake}
        setOpen={setSnake}
      />
    </>
  );
}

export default AddFunds;
