import React from "react";
import '../css/components/modal.scss'
import { useNavigate } from "react-router-dom";
import {motion} from 'framer-motion'

const NoIdModal = ({header, content }) => {
    const navigate = useNavigate()
  return (
    <>
        <div className="modal-container">
          <motion.div
        initial={{
            y:100,
            opacity:0
        }}
        animate={{
            y:0,
            opacity:1
        }}
        transition={{
            duration:0.2
        }} className="modal-content">
            <h3>{header}</h3>
            <p>{content}</p>
            <button className="btn-primary" onClick={()=>navigate('/list-video')}>Okay</button>
          </motion.div>
        </div>
    </>
  );
};

export default NoIdModal;
