import React, { useEffect, useRef, useState } from "react";
import "../../css/set-campaign/singlevideo.scss";
import { GrLocation } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { removeCountry, selectCountry } from "../../redux/common/country";
let countriesList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Austrian Empire",
  "Azerbaijan",
  "Bahamas, The",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Bavaria",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin (Dahomey)",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Brunswick and Lüneburg",
  "Bulgaria",
  "Burkina Faso (Upper Volta)",
  "Burma",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands, The",
  "Central African Republic",
  "Central American Federation",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo Free State, The",
  "Costa Rica",
  "Cote d’Ivoire (Ivory Coast)",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Czechoslovakia",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Duchy of Parma",
  "East Germany (German Democratic Republic)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Federal Government of Germany (1848-49)",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia, The",
  "Georgia",
  "Germany",
  "Ghana",
  "Grand Duchy of Tuscany",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Hanover",
  "Hanseatic Republics",
  "Hawaii",
  "Hesse",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kingdom of Serbia/Yugoslavia",
  "Kiribati",
  "Korea",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Lew Chew (Loochoo)",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mecklenburg-Schwerin",
  "Mecklenburg-Strelitz",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nassau",
  "Nauru",
  "Nepal",
  "Netherlands, The",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North German Confederation",
  "North German Union",
  "North Macedonia",
  "Norway",
  "Oldenburg",
  "Oman",
  "Orange Free State",
  "Pakistan",
  "Palau",
  "Panama",
  "Papal States",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Piedmont-Sardinia",
  "Poland",
  "Portugal",
  "Qatar",
  "Republic of Genoa",
  "Republic of Korea (South Korea)",
  "Republic of the Congo",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Schaumburg-Lippe",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands, The",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Texas",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Two Sicilies",
  "Uganda",
  "Ukraine",
  "Union of Soviet Socialist Republics",
  "United Arab Emirates, The",
  "United Kingdom, The",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Württemberg",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const Country = () => {
  const [showCountryDiv, setShowCountryDiv] = useState(false);
  const selectdCountryDiv = useRef(null);
  const closeList = useRef(null);
  const [countries, setCountries] = useState([...countriesList]);
  const dispatch = useDispatch();
  const { countryList } = useSelector((state) => state.countryList);

  const handleSelectCountries = (val) => {
    const span = document.createElement("span");
    span.innerText = val + " X ";
    span.className = "span-country-name";
    const addContent = span.innerText.split("X", 1).toString().trim();
    if (countryList.includes(addContent)) {
      return;
    }
    dispatch(selectCountry(addContent));
    span.onclick = () => {
      const content = span.innerText.split("X", 1).toString().trim();
      dispatch(removeCountry(content));
      selectdCountryDiv.current.removeChild(span);
    };

    selectdCountryDiv.current.appendChild(span);
    setShowCountryDiv(false);
  };

  const handleChange = (e) => {
    if (e.target.value === "") {
      return setCountries(countriesList);
    }
    const filtered = countriesList.filter((val) =>
      val.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCountries(filtered);
  };

  const handleClickOutside = (e) => {
    if (selectdCountryDiv.current && !selectdCountryDiv.current.contains(e.target)) {
      setShowCountryDiv(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); 
  
  return (
    <>
      <div className="country-input-container"
      onClick={(e) => e.stopPropagation()}

      >
        
        <input
          type="text"
          className="country-input"
          placeholder="Select Countries"
          onClick={() => setShowCountryDiv(true)}
          onChange={handleChange}
        />
        <div ref={selectdCountryDiv} className="span-country-list-div"></div>
        <GrLocation
          style={{
            position: "absolute",
            left: "30px",
            top: "22px",
            fontSize: "1.4rem",
            opacity: 0.3,
          }}
        />
      </div>
      <div
        className="country-list"
        style={{
          maxHeight: showCountryDiv ? "220px" : "0px",
        }}
      >
        {countries.map((val, i) => (
          <div
            className="country-name-div"
            key={i}
            onClick={() => handleSelectCountries(val)}
          >
            {val}
          </div>
        ))}
      </div>
    </>
  );
};

export default Country;
