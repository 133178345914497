import React from "react";
import { IoIosTime } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { BiSolidShield } from "react-icons/bi";
import "../../css/add-fund/mobiletransaction.scss";
import NodataImage from '../../assets/nodata.svg'
import { IoMdWallet } from "react-icons/io";

const ShowAddFundTransaction = ({ data=[] }) => {
  if(data.length === 0){
    return (
      <div style={{
        width:"100%",
        background:"whitesmoke",
        textAlign:"center",
        paddingBlock:"10px",
        boxShadow:"0 3px 16px rgba(110, 125, 177, 0.28)",
        borderRadius:"5px"
      }}>
        <h3>No Payment Available</h3>
        <img src={NodataImage} alt="" srcset="" />
         </div>
    )
  }
  return (
    <>
      {data?.map((val, i) => (
        <div className="mobile-user-trans-div">
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                background:val?.status === "Success" ? "green" : val?.status === "Failed" ? "red": "purple",
                paddingInline:"3px",
                paddingBlock:"1px",
                borderRadius:"5px",
                color:"white",
                overflow:"hidden"
              
              }}
            >
             <p style={{
                fontSize:"0.75rem",
                fontWeight:520
             }}> {val?.status}</p>
            </div>
            <div className="user-info-transaction">
           <h3 style={{
            fontSize:"1rem",
            fontWeight:500
           }}>₹{val?.amount}</h3>
          </div>
          <div className="user-info-transaction">
            <IoMdWallet /> <h3>Type : </h3> <p>₹ {val?.type}</p>
          </div>
          <div className="user-info-transaction">
            <IoMdWallet /> <h3>Wallet Deduction : </h3> <p>₹ {val?.walletDeductedAmount}</p>
          </div>
          <div className="user-info-transaction">
            <FaEye /> <h3>Status : </h3> <p>{val?.status}</p>
          </div>
          <div className="user-info-transaction">
            <IoIosTime /> <h3>Time :</h3> <p>{val?.createdAt}</p>
          </div>
          <div className="user-info-transaction">
            <BiSolidShield /> <h3>TD:</h3> <p>{val?.merchantTransactionId}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default ShowAddFundTransaction;
