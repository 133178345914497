import React from "react";
import '../css/components/modal.scss'
import {motion} from 'framer-motion'

const Modal = ({ open, setopen, header, content,icon }) => {
  return (
    <>
      {open && (
        <div
        className="modal-container">
          <motion.div
        initial ={{
          y:100
        }}
        animate ={{
          y:0
        }}
        transition={{type:"spring",stiffness:"100"}} className="modal-content">
            <h3>{header}</h3>
              {icon }
            <p>{content}</p>
            <button className="btn-primary" onClick={()=>setopen(false)}>Okay</button>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default Modal;
