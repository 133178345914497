import React, { useEffect, useState } from "react";
import "../../css/set-campaign/singlevideo.scss";
import Country from "./Country";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate} from "react-router-dom";
import Loader from "../../components/Loader";
import CryptoJS from "crypto-js";
import uniqid from "uniqid";
import { Toast } from "../../components/Toast";
import { saveVideoCampaignActon } from "../../redux/video-info/saveVideoCampaign";
import { resetCountry } from "../../redux/common/country";
import { ImPriceTag } from "react-icons/im";
import { BiSolidOffer } from "react-icons/bi";
import NoIdModal from "../../components/NoIdModel";
import { getAllPromoCodesAction } from "../../redux/meta/getAllPromoCodes";
import { IoMdCloseCircle } from "react-icons/io";

let message;
const PackageVideo = () => {
  // states
  const [promoCode, setPromoCode] = useState("");
  const [applyPromo, setApplyPromo] = useState(false);
  const [promotionLink, setPromotionLink] = useState("");
  const[promoCodeId,setPromoCodeId] = useState("");
  const [snake, setSnake] = useState(false);
  const [extraFeatures, setExtraFeatures] = useState("");
  const secretKey = "yourSecretKey";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // local storage
  const videoId = localStorage.getItem("videoId");
  const data = JSON.parse(localStorage.getItem("videoInfo"))
  const campignId = localStorage.getItem("campignId");
  const budgetDetails = JSON.parse(localStorage.getItem("package"));

  // redux
  const {data:promocodeData} = useSelector(state=>state.getPromoCode)
  const { loading: saveCampaignLoading } = useSelector(
    (state) => state.saveVideoCampaign
  );
  const { countryList } = useSelector((state) => state.countryList);

 
  useEffect(() => {
    dispatch(resetCountry()); 
      if (!campignId) {
        localStorage.setItem("campignId", uniqid());
      }

  }, []);
  
  useEffect(()=>{
    dispatch(getAllPromoCodesAction())
  },[])

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  const handleSubmitCampaign = async () => {
    if (!extraFeatures) {
      message = "Please select the Extra Features";
      setSnake(true);
      return;
    }

    if (countryList?.length <= 0) {
      message = "Please select the targeted countries";
      setSnake(true);
      return;
    }
    const jsonString = JSON.stringify({
      videoData: [videoId],
      budget: budgetDetails?.finalAmount,
      targetViews: budgetDetails?.views,
      extraFeatures: extraFeatures,
      campaignId: campignId || uniqid(),
      targetCountries: countryList,
      promotionLink: promotionLink,
      promoCode:promoCodeId
    });
    const ciphertext = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
    const saveVideoCampaign = await dispatch(
      saveVideoCampaignActon({ campaignData: ciphertext })
    );
    if (saveVideoCampaign?.payload?.status === 200) {
      localStorage.setItem(
        "paymentData",
        JSON.stringify({
          amount: budgetDetails?.finalAmount,
          campignId: campignId,
          views: budgetDetails?.views,
        })
      );
      return navigate(`/make-payment/${saveVideoCampaign?.payload?.data}`);
    }
    message = saveVideoCampaign?.payload?.message;
    setSnake(true);
  };
  
  const features = [
    "Subscribers",
    "Likes & Comments",
    "Website Traffic",
    "Social Fans",
    "App Installs",
  ];

  const handlPromotionLink = (e) => {
    setPromotionLink(e.target.value);
  };

  const HandleApplyPromo = () => {
    if (!promoCode) {
      return;
    }
    setApplyPromo(!applyPromo);
    setPromoCodeId("");
  };

  const placeHolder = ()=>{
    if(extraFeatures.includes("Website Traffic")){
      return "Enter Website Link"
    }
    if (extraFeatures.includes("Social Fan")){
      return "Enter  Social Fan Link"
    }
    if (extraFeatures.includes("App Install")){
      return "Enter App Link"
    }
  }

  if(!data || !videoId){
    return (
      <NoIdModal
        header={"Error"}
        content={"Something went wrong. Please try again after sometime."}
      />
    )
  }

 
  return (
    <>
      <div className="campaign-video">
        <div className="show-yt-video-thumbnail">
          <img
            src={data?.thumbnail}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "20px",
            }}
          />
          <p>{data?.title}</p>
          <div className="count-view-div">
            <h3>Estimated Views</h3>
            <h4>{budgetDetails?.views}</h4>
          </div>
        </div>

        <div className="campaign-setting">
          <div className="packgae-amount-div">
            <ImPriceTag />
            <h3>₹ {budgetDetails?.finalAmount} </h3>
            <div className="package-discount">
              <del>₹ {budgetDetails?.amount} </del>
              <p>{budgetDetails?.discount}% OFF </p>
            </div>
          </div>

          
          {
          applyPromo &&   <div className="apply-promo-div">
          <BiSolidOffer
            style={{
              position: "absolute",
              left: "25px",
              top: "13px",
              fontSize: "1.4rem",
              opacity: 0.3,
            }}
          />
         
          <input
          disabled
            type="text"
            className="country-input"
            placeholder=" Apply Promo"
            value={"Applied" + " " + promoCode?.toUpperCase()}
            style={{
              border:"none",
              backgroundImage:
                applyPromo &&
                "linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)",
            }}
          />
          <IoMdCloseCircle onClick={HandleApplyPromo} style={{
            fontSize:"2.5rem",
            cursor:"pointer"
          }}/>
     
         
        </div>
        }
          {promocodeData.map((val, i) => (
            <div className="offer-promo-div">
              <h4>{val?.offerName === "first50" ? "Get 50% Cashback  only for new users.": val?.offerName === "vip50" ? "Get 50% Cashback on amount above ₹5,000" : "Get 100% Cashback on amount above ₹10,000" }</h4>
              <button className="btn-primary" onClick={()=>{setPromoCode(val.offerName); setPromoCodeId(val._id); setApplyPromo(true)}} > {val.offerName?.toUpperCase()}</button>
            </div>
          ))}
          <br />
          <br />
          <br />
          <h2>Target by country</h2>
          <br />
          <Country />
          <br />
          <div className="apart-from-view">
            <h2>What do you want besides views?</h2>
            <div className="promotion-optons-div">
              {features.map((val, i) => (
                <button
                  style={{
                    background: extraFeatures === val ? "red" : "black",
                  }}
                  className="btn-primary"
                  onClick={() => setExtraFeatures(val)}
                  key={i}
                >
                  {val}
                </button>
              ))}
            </div>
            {extraFeatures.includes("Website Traffic") ||
            extraFeatures.includes("Social Fan") ||
            extraFeatures.includes("App Install") ? (
              <div className="promotion-link">
                <input
                  type="text"
                  placeholder={placeHolder()}
                  onChange={handlPromotionLink}
                />
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              <button className="btn-secondary" onClick={handleSubmitCampaign}>
              Promote Channel
              </button>
            </div>
          </div>
        </div>
      </div>
      {saveCampaignLoading && <Loader />}
      <Toast open={snake} setOpen={setSnake} message={message} />
    </>
  );
};

export default PackageVideo;
