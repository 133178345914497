import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { getVideoInfoReducer } from './video-info/getVideoInfo';
import { saveVideoCampaignReducer } from './video-info/saveVideoCampaign';
import { getChannelVideoReducer } from './channel/getChannelVideos';
import { getCountryListReducer } from './common/country';
import { getAllChannelsReducer } from './channel/getAllChannel';
import { getAllCampaignReducer } from './campaign/getAllCampaign';
import { verifyEmailReducer } from './auth/verifyEmail';
import { registerUserReducer } from './auth/register';
import { loginUserReducer } from './auth/login';
import { addChannelReducer } from './channel/addChannel';
import { myProfileReducer } from './auth/myProfile';
import { getAllPackagesReducer } from './package/getAllPackages';
import { saveMonetizeCampaignReducer } from './channel/saveMonetizeCampaign';
import { manageIdReducer } from './common/manageId';
import { makePaymentReducer } from './payment/makePayment';
import { getUserSpentReducer } from './payment/getUserSpents';
import { getChannelByIdReducer } from './channel/getChannelById';
import { getPaymentTransactionReducer } from './payment/getPaymentTransaction';
import { addBalanceReducer } from './payment/addBalance';
import { deleteCampaignReducer } from './campaign/deleteCampaign';
import { showCampaignLinkedVideosReducer } from './common/manageLinkedVideos';
import { getAllPromoCodesReducer } from './meta/getAllPromoCodes';
import { getSinglePaymentTransactionReducer } from './payment/getSingleTransaction';

const rootReducer = combineReducers({
  // meta
  getPromoCode:getAllPromoCodesReducer,
  // videos
 fetchVideoDetails:getVideoInfoReducer,
 saveVideoCampaign:saveVideoCampaignReducer,
 getChannelVideo:getChannelVideoReducer,
//  country
countryList:getCountryListReducer,

// maangevideoId channelId
manageId:manageIdReducer,

// manage linked videos
showLinkedVideos:showCampaignLinkedVideosReducer,
// channel
getAllChannels:getAllChannelsReducer,
addChannel:addChannelReducer,
buyMonetize:saveMonetizeCampaignReducer,
getChannel:getChannelByIdReducer,
// campaign
allCampaign:getAllCampaignReducer,
deleteCampaign:deleteCampaignReducer,
// auth
verifyEmail:verifyEmailReducer,
registerUser:registerUserReducer,
loginUser:loginUserReducer,
getProfile:myProfileReducer,

// package
getPackages:getAllPackagesReducer,
// payment
makePayment:makePaymentReducer,
userSpent:getUserSpentReducer,
addBalance:addBalanceReducer,
paymentTransaction:getPaymentTransactionReducer,
singlePaymentTransaction:getSinglePaymentTransactionReducer
});

export const store = configureStore({
  reducer: rootReducer,
});
