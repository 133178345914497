import React, { useEffect, useState } from "react";
import "../../css/start/start.scss";
import { BiSolidVideos } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { getAllChannelsAction } from "../../redux/channel/getAllChannel";
import { useDispatch, useSelector } from "react-redux";
import { getVideoInfoAction } from "../../redux/video-info/getVideoInfo";
import { numberFormatter } from "../../helper/numberFormatter";
import Loader from "../../components/Loader";
import { getAllPackagesAction } from "../../redux/package/getAllPackages";
import PackageImage from "../../assets/offer.svg"
import { setChannelId } from "../../redux/common/manageId";
import NoIdModal from "../../components/NoIdModel";
import { Button } from "@mui/material";
import { CiYoutube } from "react-icons/ci";
import { Toast } from "../../components/Toast";

let message;
const StartPromotion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [snake,setSnake] = useState(false);
  const { data, loading ,status} = useSelector((state) => state.fetchVideoDetails);
  const channelId = localStorage.getItem("channelId");
  const { data: channelData } = useSelector((state) => state.getAllChannels);
  const { data: packageData } = useSelector((state) => state.getPackages);
  const videoId = localStorage.getItem("videoId");

  useEffect(() => {
    if (videoId) {
      dispatch(getVideoInfoAction({ videoId: videoId }));
    }
  }, []);

  useEffect(() => {
    dispatch(getAllChannelsAction());
    dispatch(getAllPackagesAction());
  }, []);

  if (!videoId) {
    return (
      <NoIdModal
        header={"Error"}
        content={"Something went wrong. Please try again after sometime."}
      />
    );
  }

  if (!loading && status !==200) {
    return (
      <NoIdModal
        header={"Server Error"}
        content={"Something went wrong. Please try again after sometime."}
      />
    );
  }

  const handleSingleVideoPromotion = () => {
    if (channelId) {
      return navigate(`/select-single-video`);
    }
    navigate(`/save-single-video-campaign`);
    localStorage.setItem("videoInfo",JSON.stringify({thumbnail:data?.thumbnail?.url,title:data?.title}))
  };

  const handleMultipleVideoPromotion = () => {
    dispatch(setChannelId(data?.chanelId));
    navigate(`/select-videos`);
  };

  const connectChannel = async () => {
  };

  return (
    <>
      <div className="body-container start-container">
        <div className="channel-view-div">
          <div className="channel-div">
            <div className="channel-img">
              <img
                src={data?.channelProfileImg?.url}
                alt=""
                style={{
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="channel-info">
              <h3>{data?.channelName}</h3>
              <p>{numberFormatter(data?.subscribers)} Subscribers</p>
            </div>
          </div>
          <h4 className="channel-sub-heading">Upgrade your channel to next level</h4>
          {!channelData?.youtube?.accessToken && (
            <button
              className="btn-primary"
              style={{
                width: "100%",
              }}
              onClick={()=>{message = "Service Not Available"; setSnake(true)}}
            >
              Connect Channel Now
            </button>
          )}
        </div>

        <div className="select-promotion-option">
          <h3>Select what to do</h3>
          <br />
          <div className="select-video-type">
            <div className="promote-video-div">
              <Button variant="contained"  onClick={handleSingleVideoPromotion} startIcon ={ <CiYoutube />}>
              Promote Single Video
              </Button>
              <Button onClick={handleMultipleVideoPromotion} variant="contained" startIcon ={ <BiSolidVideos />}>
              Promote Multiple Videos
              </Button>
            </div>
          </div>
          <br />
          <h3>Select from Bulk views</h3>
          <br />
          <div className="bulk-view-container">
            {packageData.map((val, i) => (
              <div
                className="bulk-view"
                key={i}
                onClick={() => {
                  localStorage.setItem("videoInfo",JSON.stringify({thumbnail:data?.thumbnail?.url,title:data?.title}))
                  localStorage.setItem("package", JSON.stringify(val));
                  navigate("/save-package-video-campaign");
                }}
              >
                <img src={PackageImage} alt="offer" />
                <div className="package-info">
                  <h3>{val.packageName}</h3>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent:"center",
                      gap: 10,
                    }}
                  >
                    {" "}
                    <h4>₹ {val.finalAmount}</h4>
                    <del> ₹ {val.amount}</del>
                    <p>{val.discount}% OFF</p>
                  </div>
                  <p>Views : {val.views}</p>
                  <button className="btn-primary" style={{
                    width:"100%"
                  }}>Buy Now</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <Toast message={message} open={snake} setOpen={setSnake}/>
    </>
  );
};

export default StartPromotion;
