import React, { useState } from "react";
import "../../css/listvideo/listvideo.scss";
import {motion } from "framer-motion";
import AddUrlInput from "./AddUrlInput";
import { FaCopy } from "react-icons/fa";
import { SiGoogleanalytics } from "react-icons/si";
import { SiCashapp } from "react-icons/si";
import google from "../../assets/google.webp";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../components/Toast";
import { useDispatch } from "react-redux";
import { setVideoId } from "../../redux/common/manageId";
import { Box } from "@mui/material";
let message;
const PromoteVideo = () => {
  const dispatch = useDispatch()
  const [snake, setSnake] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const navigate = useNavigate();
  const handleChangeInput = (e) => {
    setVideoLink(e.target.value);
  };

  const handleKeyDown = (e) => {
    console.log("working");
    if (e.key === "Enter") {
      console.log("Enter key pressed");
      submitVideoLink();
    }
  };

  const submitVideoLink = () => {
    localStorage.removeItem("campignId")
    const videoIdMatch = videoLink.match(/(?:\/|%3D|v=)([0-9A-Za-z_-]{11})(?:\S+)?$/);

    if (videoIdMatch) {
      const videoId = videoIdMatch[1];
      dispatch(setVideoId(videoId))
      localStorage.removeItem("channelId")
      navigate(`/start`);
    } else {
      message = "Invalid YouTube video link";
      setSnake(true);
    }
  };

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          transform: "scale(0.9)",
        }}
        animate={{
          opacity: 1,
          transform: "scale(1)",
        }}
        transition={{
          duration: 0.2,
        }}
        className="body-container"
        style={{
          display:"flex",
          alignItems:"center",
          flexDirection:"column",
          justifyContent:"center"
        }}
      >
        <div className="upper-listing-div">
          <div className="goole-div">
            <h2
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <img src={google} alt="" width={100} srcset="" /> Partner
            </h2>
          </div>
          <h1>
            Start Your <strong>YouTube Promotions </strong> with just{" "}
            <strong>₹10</strong>{" "}
          </h1>
          <Box className = "video-link-input" width={"80%"}>
          <AddUrlInput
            placeholder={"Enter Your Youtube Video Link"}
            value={videoLink}
            name={"videoLink"}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            onClick={submitVideoLink}
          />
          </Box>
         
          <div className="add-url-steps">
            <div className="steps">
              <FaCopy />
              <h2>Enter URL Link</h2>
              <p>Enter the URL which you want to promote</p>
            </div>
            <div className="steps">
              <SiGoogleanalytics />
              <h2>Set Up your Campaign</h2>
              <p>
                Set your target audience, budget, and check the estimated views
              </p>
            </div>
            <div className="steps">
              <SiCashapp />
              <h2>Make Payment</h2>
              <p>
                Complete the payment, sit back and watch your video
                go viral
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <Toast open={snake} setOpen={setSnake} message={message} />
    </>
  );
};

export default PromoteVideo;
