import React, { useState } from "react";
import "../../css/monetize/monetize.scss";
import Image from "../../assets/offer.webp";
import AddUrlInput from "../promote-video/AddUrlInput";
import FAQ from "../../components/FAQ";
import { useNavigate } from "react-router-dom";
import { Toast } from "../../components/Toast";
import { Box } from "@mui/material";

const faqData = [{
  question:"Why Do We Need The Analytics Access To Your YouTube Channel?",
  answer:"YouTube allows you to collaborate with other parties by giving a variety of access permissions. To begin the monetization package, we need to view the analytics, as this helps us look at your Channel's past performance, keep track of the analytics, and make sure the promotions are going as planned. It's the least invasive access which only allows us to view the progress of our ad campaigns and optimize our efforts to help you achieve your goals faster."
},
{
  question:"Advantages Of The Monetization Package?",
  answer:" Start Earning Quicker - VeeFly Monetization package helps you accelerate the process of monetizing your Channel. You can start earning from doing what you have been doing all along: Create awesome content!"
},
{
  question:"How VeeFly Gets Views For You?",
  answer:"Our team of experts analyses your content and devises a unique campaign strategy to promote your video through various trusted channels. As a result, your video is promoted to a carefully selected audience group that will most likely engage with your content."
},
{
  question:"Advantages Of The Monetization Package?",
  answer:" Start Earning Quicker - VeeFly Monetization package helps you accelerate the process of monetizing your Channel. You can start earning from doing what you have been doing all along: Create awesome content!"
},
{
  question:"How Much Time Would It Take For My Channel To Get Monetized After The Package Initiates?",
  answer:"It Would take about 3-6 weeks for your Channel to reach the eligibility criteria of 4000 watch hours and 1000 subscribers after initiating the package.We use this time to devise multiple strategies and promote your Channel to the most relevant audience who tend to watch your videos for a longer duration and subscribe."
}
]
let message;
const MonetizeChannel = () => {
  const navigate = useNavigate();
  const [videoLink, setVideoLink] = useState("");
 const [snake,setSnake] = useState(false)
  const handleChangeInput = (e) => {
    setVideoLink(e.target.value);
  };
  const handleSubmitURL = () => {
    const videoIdMatch = videoLink.match(/[?&]v=([^&]+)/);
    if (videoIdMatch) {
      const videoId = videoIdMatch[1];
      console.log("Video ID:", videoId);
      localStorage.setItem("videoId", videoId);
      navigate(`/buy-channel-monetize-package`);
    } else {
      message = "Invalid YouTube video link";
      setSnake(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSnake(true)
      message ="We have paused this servive ! Will start soon."
    }
  };
  
  return (
    <>
    <div className="body-container">
      <div className="upper-monetize-div">
        <div className="monetize-image">
        <img
          src={Image}
          alt="monetize"
        />
        </div>
       
        <div className="monetize-offer-div">
          <h2>YouTube Monetization Package</h2>
          <h2>₹ 999</h2>
        
          <span style={{
            display:"flex",
            gap:"10px"
          }}> <del>₹ 1491</del>  <p>50% OFF</p></span>
         
          <p>
            The VeeFly Monetization Package is a gift for all the hardworking
            YouTube content creators out there. The package is built to bridge
            the gap between your efforts and a running income. The Monetization
            package offers 4000 watch hours and 1000 subscribers helping you
            meet the YouTube Monetization criteria in a period of just 3-6
            weeks.
          </p>
        </div>
      </div>
      <br />
      <h2>Search your Youtube channel</h2>
      <br />
      <AddUrlInput
          placeholder={"Enter Your Video Link "}
          onChange={handleChangeInput}
          onKeyDown={handleKeyDown}
          onClick={handleSubmitURL}
        />
      <br />
      <Box bgcolor={"white"} sx={{
         boxShadow:"0 3px 16px rgba(110, 125, 177, 0.28);",
         borderRadius:2
      }}>
      <FAQ data={faqData}/>
      </Box>
    
    </div>
    <Toast message={message} open={snake} setOpen={setSnake}/>
    </>
  );
};

export default MonetizeChannel;
