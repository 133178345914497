import React from 'react'
import '../css/components/loader.scss'
import {motion} from 'framer-motion'
const Loader = () => {
  return (
    <div className='loader-body'>
<section class="dots-container">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </section>
    </div>
    
  
  )
}

export default Loader