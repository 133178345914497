import React, { useState } from "react";
import { motion } from "framer-motion";
import AddUrlInput from "../promote-video/AddUrlInput";
import { FaCopy } from "react-icons/fa";
import { SiGoogleanalytics } from "react-icons/si";
import { SiCashapp } from "react-icons/si";
import google from "../../assets/google.webp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVideoInfoAction } from "../../redux/video-info/getVideoInfo";
import Loader from "../../components/Loader";
import { Toast } from "../../components/Toast";
import { setChannelId } from "../../redux/common/manageId";
import { Box } from "@mui/material";

let message;
const PromoteChannel = () => {
  const [channelLink, setChannelLink] = useState("");
  const [snake, setSnake] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {loading } = useSelector((state) => state.fetchVideoDetails);

  const handleChangeInput = (e) => {
    setChannelLink(e.target.value);
  };
  const handleSubmitURL = async () => {
    localStorage.removeItem("campignId")
    let channelId;
    if (!channelLink) {
      return;
    }
    const channelIdRegex = /channel\/([\w-]{24,34})/;
    const matches = channelLink.match(channelIdRegex);
    channelId = matches ? matches[1] : null;

    if (channelId) {
      dispatch(setChannelId(channelId))
      navigate(`/select-videos`);
    } else {
      const videoIdMatch = channelLink.match(/[?&]v=([^&]+)/);
      if (videoIdMatch) {
        const videoId = videoIdMatch[1];
        const getChannelId = await dispatch(
          getVideoInfoAction({ videoId: videoId })
        );
      dispatch(setChannelId(getChannelId?.payload?.data?.chanelId))
        navigate(`/select-videos`);
      } else {
        message = "Invalid YouTube video link";
        setSnake(true);
      }
    }
  };
  const handleKeyDown = async (e) => {
    console.log("working");
    if (e.key === "Enter") {
      console.log("Enter key pressed");
      await handleSubmitURL();
    }
  };
  
  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          transform: "scale(0.9)",
        }}
        animate={{
          opacity: 1,
          transform: "scale(1)",
        }}
        transition={{
          duration: 0.2,
        }}
        className="body-container"
      >
        <div className="upper-listing-div">
          <div className="goole-div">
            <h2
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
            >
              <img src={google} alt="" width={100} srcset="" /> Partner
            </h2>
          </div>
          <h1>
            Start Your <strong>YouTube Promotions </strong> with just{" "}
            <strong>₹10</strong>{" "}
          </h1>
         <Box className = "video-link-input">
         <AddUrlInput
            placeholder={"Enter Your Channel Or Video Link"}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            onClick={handleSubmitURL}
          />
         </Box>
          <div className="add-url-steps">
            <div className="steps">
              <FaCopy />
              <h2>Enter URL Link</h2>
              <p>Enter the URL which you want to promote</p>
            </div>
            <div className="steps">
              <SiGoogleanalytics />
              <h2>Set Up your Campaign</h2>
              <p>
                Set your target audience, budget, and check the estimated views
              </p>
            </div>
            <div className="steps">
              <SiCashapp />
              <h2>Make Payment</h2>
              <p>
                Complete the payment, and voila, sit back and watch your video
                go viral
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      {loading && <Loader />}
      <Toast message={message} open={snake} setOpen={setSnake}/>
    </>
  );
};

export default PromoteChannel;
