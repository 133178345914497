import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    videoData: [],showVideos:false
}

export const showCampaignLinkedVideosSlice = createSlice({
    name: 'showCampaignLinkedVideos',
    initialState,
    reducers: {
        setVideoData: (state, action) => {
            state.videoData = action.payload
        },
        showVideo:(state, action) => {
           state.showVideos = !state.showVideos
        },

    },
})

export const { setVideoData,showVideo } = showCampaignLinkedVideosSlice.actions;
export const showCampaignLinkedVideosReducer = showCampaignLinkedVideosSlice.reducer;
