import React, { useEffect, useState } from "react";
import "../../css/campaign/campaign.scss";
import { IoRadioButtonOnOutline } from "react-icons/io5";
import MonetIzeImage from "../../assets/monetize.jpeg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../components/Toast";
import Loader from "../../components/Loader";
import { deleteCampaignAction } from "../../redux/campaign/deleteCampaign";
import { getAllCampaignAction } from "../../redux/campaign/getAllCampaign";
import mulptileVideos from "../../assets/multiple.svg";
import { setVideoData, showVideo } from "../../redux/common/manageLinkedVideos";
import {numberFormatter} from '../../helper/numberFormatter'
const CampaignCard = ({
  videoInfo,
  id,
  targetViews,
  paymentStatus,
  status,
  title,
  spentAmount,
  views,
  campaignId,
  budget,
  thumbnail,
}) => {
  const navigate = useNavigate();
  const [snake, setSnake] = useState(false);
  const {
    message,
    loading,
    status: deleteStatus,
  } = useSelector((state) => state.deleteCampaign);
  const dispatch = useDispatch();

  const handleDelete = async (val) => {
    await dispatch(deleteCampaignAction({ camaignId: val }));
    await dispatch(getAllCampaignAction());
    setSnake(true);
  };
  
  return (
    <>
      <div
        style={{
          marginTop: "40px",
          position: "relative",
          width: "100%",
        }}
      >
        {paymentStatus === "Not Initiated" || paymentStatus === "Failed" ? (
          <button
            onClick={() => handleDelete(id)}
            className="delete-campaign-button"
          >
            {" "}
            DELETE{" "}
          </button>
        ) : (
          ""
        )}

        {paymentStatus === "Not Initiated" || paymentStatus === "Failed" ? (
          <button
            onClick={() => {
              localStorage.setItem(
                "paymentData",
                JSON.stringify({
                  amount: budget,
                  campignId: campaignId,
                  views: targetViews,
                })
              );
              navigate(`/make-payment/${id}`);
            }}
            className="pending-payment-campaign-button"
          >
            {" "}
            PAY ₹ {numberFormatter(budget)}{" "}
          </button>
        ) : (
          ""
        )}
        {/* progress */}
        <div className="campaign-progress">
          <div className="progress">
            <IoRadioButtonOnOutline
              style={{
                color: status === "Pending" ? "red" : "rgba(0,0,0,0.2)",
              }}
            />
            <h4>Pending</h4>
          </div>
          <div className="progress">
            <IoRadioButtonOnOutline
              style={{
                color: status === "Under Review" ? "red" : "rgba(0,0,0,0.2)",
              }}
            />
            <h4>Under Review</h4>
          </div>
          <div className="progress">
            <IoRadioButtonOnOutline
              style={{
                color: status === "In Progress" ? "red" : "rgba(0,0,0,0.2)",
              }}
            />
            <h4>In Progress</h4>
          </div>
          <div className="progress">
            <IoRadioButtonOnOutline
              style={{
                color: status === "Completed" ? "red" : "rgba(0,0,0,0.2)",
              }}
            />
            <h4>Completed</h4>
          </div>
        </div>

        {/* info */}
        {videoInfo?.length > 1 ? (
          <div className="campaign-info">
            <div className="yt-thumbnail">
              <img
                src={mulptileVideos}
                alt={campaignId}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                  height: "100%",
                }}
              />
            </div>
            <div className="yt-video-info">
              <h3 className="yt-video-title">
                Multiple Videos | Linked Videos : {videoInfo?.length}
              </h3>
              <p>Budget : ₹ {budget}</p>
              <p>Campign Id : {campaignId}</p>
              <button className="show-campaign-videos" onClick={()=>{dispatch(showVideo());dispatch(setVideoData(videoInfo))}} >View Videos</button>
            </div>
            <div className="yt-video-views">
              <div className="views">
                <h3>Views</h3>
                <p>{views}</p>
              </div>
              <div className="views">
                <h3>Spent</h3>
                <p>₹ {spentAmount}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="campaign-info">
            <div className="yt-thumbnail">
              <img
                src={thumbnail || MonetIzeImage}
                alt={campaignId}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  borderRadius: "10px",
                  height: "100%",
                }}
                srcset=""
              />
            </div>
            <div className="yt-video-info">
              <h3 className="yt-video-title">{title}</h3>
              <p>Budget : ₹ {budget}</p>
              <p>Campign Id : {campaignId}</p>
              {/* <p>Date : {data}</p> */}
            </div>
            <div className="yt-video-views">
              <div className="views">
                <h3>Views</h3>
                <p>{views}</p>
              </div>
              <div className="views">
                <h3>Spent</h3>
                <p>₹ {spentAmount}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Toast
        open={snake}
        setOpen={setSnake}
        message={message}
        status={deleteStatus}
      />
      {loading && <Loader />}

    </>
  );
};

export default CampaignCard;
