import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../config/authaxios"


const initialState = { status: null, message: "", loading: false, data: [], error: "" }

export const getChannelVideoAction = createAsyncThunk(
    'channel-video',
    async (data, { rejectWithValue }) => {
        try {
         
            const res = await authAxios.get(`/channel-video?channelId=${data?.channelId}&title=${data?.title}`,)
           
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getChannelVideoSlice = createSlice({
    name: 'channel-video',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getChannelVideoAction.pending, (state) => {
                state.data = []
                state.loading = true
            })
            builder.addCase(getChannelVideoAction.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.loading = false
                state.message = action.payload.message
                state.status = action.payload.status
            })
            builder.addCase(getChannelVideoAction.rejected, (state, action) => {
            
                state.loading = false
                state.error = action?.payload?.error
                state.message = action.payload?.message
                state.status = action.payload?.status
            })
        },

})



export const getChannelVideoReducer = getChannelVideoSlice.reducer