import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../config/authaxios"


const initialState = { status: null, message: "", loading: false, data: [], error: "" }

export const loginUserAction = createAsyncThunk(
    'register',
    async (data, { rejectWithValue }) => {
        try {
            const res = await authAxios.post(`/auth/login`, {...data})
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const loginUserSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(loginUserAction.pending, (state) => {
                state.data = []
                state.loading = true
            })
            builder.addCase(loginUserAction.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.loading = false
                state.message = action.payload.message
                state.status = action.payload.status
            })
            builder.addCase(loginUserAction.rejected, (state, action) => {
            
                state.loading = false
                state.error = action?.payload?.error
                state.message = action.payload?.message
                state.status = action.payload?.status
            })
        },

})



export const loginUserReducer = loginUserSlice.reducer