import React, { useEffect } from "react";
import "../../css/auth/verifyemail.scss";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmailAction } from "../../redux/auth/verifyEmail";
import { MdVerified } from "react-icons/md";
import { MdError } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import TransparentLoader from "../../components/TransparentLoader";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { message, loading ,status} = useSelector((state) => state.verifyEmail);
  const {token} = useParams()
console.log(token)
  useEffect(() => {
    dispatch(verifyEmailAction(token));
  }, [token]);

  return (
    <>
   
    <div className="verify-body">
      {status === 200 ? (
        <div className="email-verification-div">
          <MdVerified
            style={{
              color: "green",
            }}
          />
          <h3>{message}</h3>
          <button className="btn-primary" onClick={()=>navigate('/')}>Login</button>
        </div>
      ) : (
        <div className="email-verification-div">
            <MdError
            style={{
              color: "red",
            }}
          />
          <h3>{message}</h3>
         
        </div>
      )}
    </div>
{loading && <TransparentLoader/>}
    </>
  );
};

export default VerifyEmail;
