import React from 'react'
import { FcStart } from "react-icons/fc";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { IoMdSearch } from "react-icons/io";
const AddUrlInput = ({onClick,button=true,icon,placeholder,value,name,onChange,onKeyDown,type="text"}) => {
  return (
    <div className='add-video-url-container'>
        {icon === "payment" ?<FaIndianRupeeSign style={{
          opacity:0.5,
          fontSize:"1.5rem"
        }} className='yt-svg'/> : <FcStart className='yt-svg'/>}
    <input className='add-video-input' type={type} name={name} value={value} onChange={onChange}
    placeholder={placeholder}
    onKeyDown={onKeyDown}
/>
  {button && <div className='clickable-arrow' onClick={onClick}>
    <IoMdSearch  />
    </div>}
    </div>
  )
}

export default AddUrlInput