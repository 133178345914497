import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: 'Poppins',
  },
  components: {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: "8px",
                fontSize:18,
                textTransform:"none",
                fontWeight:"normal",
                paddingInline:"20px",
           
            },
        },
    },
},
  
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
