import React, { useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "../../components/Toast";
import { registerUserAction } from "../../redux/auth/register";
import Modal from "../../components/Modal";
import TransparentLoader from "../../components/TransparentLoader";
import login_1_Image from "../../assets/login1.svg";

const resgiterSchema = yup.object({
  fullName: yup.string().required(),
  password: yup.string().required("Password is Required"),
  email: yup.string().required("Email is required"),
});
const Register = ({ setShowRegister }) => {
  const [snake, setSnake] = useState(false);
  const [showVerificationPop, setShowVerificationPop] = useState(false);
  const dispatch = useDispatch();
  const { message, loading, status } = useSelector(
    (state) => state.registerUser
  );
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(resgiterSchema) });

  const handleRegister = async (data) => {
    const register = await dispatch(registerUserAction(data));
    if (register?.payload?.status === 200) {
      setShowVerificationPop(true);
      setSnake(false)
      reset();
    } else {
      setSnake(true);
      setShowVerificationPop(false);
      reset();
    }
  };
  return (
    <>
      <motion.div
        initial={{
          x: 50,
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          x: 0,
        }}
        transition={{
          duration: 0.3,
        }}
        className="login-container"
      >
         <img className="top-auth-image" src={login_1_Image} alt="login" />
        <h1>Pro-Affiliate : Go Viral</h1>
        <form
          action=""
          className="login-register-form"
          onSubmit={handleSubmit(handleRegister)}
        >
           <h2>Register Now</h2>
              <p>Hi, Enter your details to register your account</p>
          <div className="input-div">
            <input
              type="text"
              name="fullName"
              {...register("fullName")}
              placeholder="Full Name"
            />
            <p className="error">
              {errors?.fullName && "Full Name is required"}
            </p>
          </div>
          <div className="input-div">
            <input
              type="email"
              name="email"
              {...register("email")}
              placeholder="Enter Email"
            />
            <p className="error">{errors?.email && "E-mail is required"}</p>
          </div>

          <div className="input-div">
            <input
              type="password"
              name="password"
              {...register("password")}
              placeholder="Enter Password"
            />
            <p className="error">
              {errors?.password && "Password is required"}
            </p>
          </div>

          <button style={{
            width:"100%"
          }} type="submit" className="btn-primary">
            Register
          </button>
        </form>
        <div className="already-register">
          <p>Already User?</p>
          <h4 onClick={() => setShowRegister(false)}>Login Here</h4>
        </div>
      </motion.div>
      <Toast message={message} status={status}  setOpen={setSnake} open={snake}/>
   <Modal open={showVerificationPop} setopen={setShowVerificationPop} header={"Verification Mail Sent"}  content={"A link has been sent on your registered email address. Please verify your email. "} />
   {loading && <TransparentLoader/>}
    </>
  );
};

export default Register;
