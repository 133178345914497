import React, { useEffect, useState } from "react";
import "../../css/auth/login.scss";
import { motion } from "framer-motion";
import Register from "./Register";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAction } from "../../redux/auth/login";
import { Toast } from "../../components/Toast";
import TransparentLoader from "../../components/TransparentLoader";
import { useNavigate } from "react-router-dom";
import LoginImage from "../../assets/login.svg";
import { Typewriter } from "react-simple-typewriter";
import login_1_Image from "../../assets/login1.svg";

const texts = [
  "Redefine Advertising",
  "Go Viral With Pro-Affiliate",
  "Promote Videos...",
];

const loginSchema = yup.object({
  password: yup.string().required("Password is Required"),
  email: yup.string().required("Email is required"),
});
const Login = ({ isAuthenticated }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [showRegister, setShowRegister] = useState();

  const navigate = useNavigate();
  const { loading, message, status } = useSelector((state) => state.loginUser);
  const [snake, setSnake] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/list-video");
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1500); // Adjust the timeout to control the typing speed

    return () => clearTimeout(timeout);
  }, [currentTextIndex, texts]);

  const handleLogin = async (data) => {
    const loginUser = await dispatch(loginUserAction(data));
    if (loginUser?.payload?.status === 200) {
      localStorage.setItem("token", loginUser?.payload?.data?.token);
      localStorage.setItem("name", loginUser?.payload?.data?.name);
      localStorage.setItem("email", loginUser?.payload?.data?.email);
      localStorage.setItem(
        "phoneNumber",
        loginUser?.payload?.data?.phoneNumber
      );
      window.location.href = "/";
    } else {
      setSnake(true);
    }
  };
  const currentText = texts[currentTextIndex];
  return (
    <>
      <div className="auth-body">
        <div className="left-auth-container">
          <img src={LoginImage} alt="login" srcset="" />
          <motion.div
            className="animate-text"
            key={currentText}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 0.3,
            }}
          >
            {currentText}
          </motion.div>
        </div>

        {showRegister ? (
          <Register setShowRegister={setShowRegister} />
        ) : (
          <motion.div
            initial={{
              x: 50,
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            transition={{
              duration: 0.3,
            }}
            className="login-container"
          >
              <img className="top-auth-image" src={login_1_Image} alt="login" />
            <div className="desktop-heading-login-container">
              <h1>Pro-Affiliate : Go Viral</h1>
            </div>
            <div className="mobile-heading-login-container">
              <h1>Pro-Affiliate : </h1>
              <div className="typing-effect">
                <Typewriter
                  words={["Go Viral", "Promotions", "Views", "Repeat!"]}
                  typeSpeed={70}
                  loop={false}
                  delaySpeed={500}
                  cursor
                  cursorStyle="_"
                />
              </div>
            </div>

            <form
              className="login-register-form"
              onSubmit={handleSubmit(handleLogin)}
            >
              <h2>Login</h2>
              <p>Hi, Enter your details to get login into your account.</p>
              <div className="input-div">
                <input
                  type="email"
                  name="email"
                  {...register("email")}
                  placeholder="Your Email"
                />
                <p className="error">{errors?.email && "E-mail is required"}</p>
              </div>

              <div className="input-div">
                <input
                  type="password"
                  name="password"
                  {...register("password")}
                  placeholder="Enter Password"
                />
                <p className="error">
                  {errors?.password && "Password is required"}
                </p>
              </div>

              <button
                style={{
                  width: "100%",
                }}
                type="submit"
                className="btn-primary"
              >
                Login
              </button>
            </form>
            <div className="already-register">
              <p>New User?</p>
              <h4 onClick={() => setShowRegister(true)}>Register Here !!</h4>
            </div>
          </motion.div>
        )}
      </div>
      <Toast
        open={snake}
        message={message}
        status={status}
        setOpen={setSnake}
      />
      {loading && <TransparentLoader />}
    </>
  );
};

export default Login;
