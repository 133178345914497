import React, { useEffect, useState } from "react";
import "../../css/sidebar/sidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { topSideBarLinks } from "./upperLinks";
import { dashboardSideBarLinks } from "./upperLinks";
import { CgMenuLeftAlt } from "react-icons/cg";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";

const MobileSidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const variants = {
    hidden: { y: 400, display: "none" },
    visible: { y: 0, display: "flex" },
  };
  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMobileMenu]);
  return (
    <>
      <Box
        boxShadow="0 3px 16px rgba(110, 125, 177, 0.28)"
        p={1}
        bgcolor="white"
        alignItems="center"
        display="flex"
        justifyContent="center"
        height="50px"
        width="50px"
        mt={1}
        ml={2}
        borderRadius={"50%"}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        {showMobileMenu ? (
          <IoMdClose
            style={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
          />
        ) : (
          <CgMenuLeftAlt
            style={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
          />
        )}
      </Box>

      <motion.div
        initial="hidden"
        animate={showMobileMenu ? "visible" : "hidden"}
        variants={variants}
        transition={{ duration: 0.2 }}
        className="sidebar-container"
        style={{
          left: 0,
          paddingInline: "15px",
        }}
      >
        <div className="profile-username">
          <FaUserCircle
            style={{
              fontSize: "2rem",
            }}
          />
          <h5>
            Welcome, {localStorage.getItem("name")?.toUpperCase() || "Error"}
          </h5>
        </div>
        <div className="sidebar-links">
          {topSideBarLinks.map((val, i) => (
            <div
              className={val.link === pathname ? "link-div-active" : "link-div"}
              onClick={() => {
                navigate(val.link);
                setShowMobileMenu(!showMobileMenu);
              }}
            >
              {val.icon}
              {val.title}
            </div>
          ))}
        </div>

        <div className="sidebar-links">
          <div className="dashboard">
            <h3>Dashboard</h3>
          </div>
          <br />
          {dashboardSideBarLinks.map((val, i) => (
            <div
              className={val.link === pathname ? "link-div-active" : "link-div"}
              onClick={() => {
                navigate(val.link);
                setShowMobileMenu(!showMobileMenu);
              }}
            >
              {val.icon}
              {val.title}
            </div>
          ))}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "3px",
            fontWeight: 520,
          }}
        >
          Log Out
          <IoMdLogOut style={{fontSize:"1.6rem"}} onClick={()=>{localStorage.clear();window.location.href ="/"}} />
        </div>
      </motion.div>
    </>
  );
};

export default MobileSidebar;
