import React, { useState } from "react";
import Table from "../../components/Table";
import {motion} from 'framer-motion'

const ChannelCamapign = () => {
    const[data,setData] = useState([])
  const column = [
    {
      headerName: "PROMOTION ID",
      type: "string",
      property: "name",
    },
    {
      headerName: "CHANNEL TITLE",
      type: "string",
      property: "name",
    },
    {
      headerName: "BUDGET",
      type: "string",
      property: "name",
    },
    {
      headerName: "STATUS",
      type: "string",
      property: "name",
    },
    {
      headerName: "PAYMENT",
      type: "string",
      property: "name",
    },
  ];

  return (
    <motion.div 
    initial={{
        opacity: 0,
        transform: "scale(0.9)",
      }}
      animate={{
        opacity: 1,
        transform: "scale(1)",
      }}
      transition={{
        duration: 0.2,
      }}
    className="body-container">
      <Table column={column}  data={data}/>
    </motion.div>
  );
};

export default ChannelCamapign;
