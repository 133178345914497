import React, { useEffect, useState } from "react";
import "../../css/monetize/buymonetizepackage.scss";
import Image from "../../assets/offer.webp";
import CryptoJS from "crypto-js";
import FAQ from "../../components/FAQ";
import { useDispatch, useSelector } from "react-redux";
import { getVideoInfoAction } from "../../redux/video-info/getVideoInfo";
import { numberFormatter } from "../../helper/numberFormatter";
import Loader from "../../components/Loader";
import uniqid from "uniqid";
import { saveMonetizeCampaignAction } from "../../redux/channel/saveMonetizeCampaign";
import {Toast} from '../../components/Toast'

const faqData = [
  {
    question: "Why Do We Need The Analytics Access To Your YouTube Channel?",
    answer:
      "YouTube allows you to collaborate with other parties by giving a variety of access permissions. To begin the monetization package, we need to view the analytics, as this helps us look at your Channel's past performance, keep track of the analytics, and make sure the promotions are going as planned. It's the least invasive access which only allows us to view the progress of our ad campaigns and optimize our efforts to help you achieve your goals faster.",
  },
  {
    question: "Advantages Of The Monetization Package?",
    answer:
      " Start Earning Quicker - VeeFly Monetization package helps you accelerate the process of monetizing your Channel. You can start earning from doing what you have been doing all along: Create awesome content!",
  },
  {
    question: "How VeeFly Gets Views For You?",
    answer:
      "Our team of experts analyses your content and devises a unique campaign strategy to promote your video through various trusted channels. As a result, your video is promoted to a carefully selected audience group that will most likely engage with your content.",
  },
  {
    question: "Advantages Of The Monetization Package?",
    answer:
      " Start Earning Quicker - VeeFly Monetization package helps you accelerate the process of monetizing your Channel. You can start earning from doing what you have been doing all along: Create awesome content!",
  },
  {
    question:
      "How Much Time Would It Take For My Channel To Get Monetized After The Package Initiates?",
    answer:
      "It Would take about 3-6 weeks for your Channel to reach the eligibility criteria of 4000 watch hours and 1000 subscribers after initiating the package.We use this time to devise multiple strategies and promote your Channel to the most relevant audience who tend to watch your videos for a longer duration and subscribe.",
  },
];
const BuyMonetize = () => {
  const [snake, setSnake] = useState(false);
  const [budget, setBudget] = useState(0);
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.fetchVideoDetails);
  const { loading:monetizeLoading ,status,message} = useSelector((state) => state.buyMonetize);
  const videoId = localStorage.getItem("videoId");
  const channelId = localStorage.getItem("channelId");
  // useEffect(() => {
  //   if (videoId) {
  //     dispatch(getVideoInfoAction({ videoId: videoId }));
  //   } else if (channelId) {
  //     dispatch(getVideoInfoAction({ channelId: channelId }));
  //   }
  // }, []);

  const connectChannel = async () => {
    if (data?.chanelId) {
      const link = `http://localhost:4000/api/v1/add-channel?channelId=${channelId}`;
      window.open(link, "blank");
    } else return;
  };

  const buyMonetizePackage = async() => {
    setBudget(999)
    const secretKey = "yourSecretKey";
    const jsonString = JSON.stringify({
      campaignId: uniqid(),
      budget: budget,
      channelId: channelId,
    });
    const ciphertext = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
    const saveCampaign = await dispatch(saveMonetizeCampaignAction({campaignData:ciphertext}))
    setSnake(true)
  };
  return (
    <>
      <div className="body-container">
        <div className="monetize-channel">
          <img
            src={data?.channelProfileImg?.url}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
            }}
            alt=""
          />
          <h4>{data?.channelName}</h4>
          <div className="monetize-channel-info">
            <h4>{numberFormatter(data?.subscribers)} Subscribers</h4>
            <h4>{data?.viewCount} Views</h4>
          </div>
          <button className="btn-primary" onClick={connectChannel}>
            Add Your Channel
          </button>
        </div>
        <br />
        <h2>Monetization requirements</h2>
        <br />
        <div className="monetize-package">
          <div className="monetize-requirement"></div>
          <div className="monetize-requirement"></div>
          <div className="monetize-requirement"></div>
        </div>
        <br />
        <div
          style={{
            width: "100%",
            background: "#ececee",
            borderRadius: "5px",
            paddingInline: "20px",
            paddingBlock: "20px",
          }}
        >
          <div className="buy-package">
            <div className="yt-thumbnail">
              <h3>YouTube Monetization Package</h3>
            </div>
            <div className="monetize-price">
              <h3 >$999</h3>
              <del>$1491</del>
            </div>
          </div>
          <button
            style={{
              width: "100%",
            }}
            className="btn-primary"
            onClick={ buyMonetizePackage}
          >
            Buy Package
          </button>
        </div>
        <FAQ data={faqData} />
      </div>
      {loading && <Loader />}
      {monetizeLoading && <Loader />}
      <Toast open={snake} setOpen={setSnake} message={message} status={status}/>
    </>
  );
};

export default BuyMonetize;
