import React, { useEffect } from "react";
import { MdVerified } from "react-icons/md";
import { BiSolidErrorCircle } from "react-icons/bi";
import { motion } from "framer-motion";
export const Toast = ({ open, setOpen, message, status }) => {
  useEffect(() => {
    const timeoutId = setTimeout(() => setOpen(false), 3000);

    return () => clearTimeout(timeoutId);
  }, [open]);

  return (
    <>
      {open && (
        <motion.div
          initial={{
            y: -100,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            type: "spring",
            stiffness: 150,
          }}
          style={{
            zIndex: 2000000,
            position:"fixed",
            width:"96%",
            height:"50px",
            display:"flex",
            justifyContent:"center",
            top:"20px",
            left:0,
            alignItems:"center",
            // background:"red"
          }}  
        >
            <div style={{
                background:"#002244",
                display:"flex",
                gap:10,
                alignItems:"center",
                justifyContent:"center",
                paddingInline:15,
                paddingBlock:10,
                borderRadius:3,
                boxShadow:"0px 10px 20px rgba(0,0,0,0.5)",
                color:"white"
            }}>
            {status === 200 ? (
            <MdVerified
              style={{
                color: "white",
                fontSize: "1.8rem",
              }}
            />
          ) : (
            <BiSolidErrorCircle
              style={{
                color: "white",
                fontSize: "1.8rem",
              }}
            />
          )}
          {message}
            </div>
            
            
   
          
        </motion.div>
      )}
    </>
  );
};


