import React, { useEffect, useRef } from "react";
import "../../css/campaign/view_campaign_videos.scss";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { showVideo } from "../../redux/common/manageLinkedVideos";
import { IoCloseCircle } from "react-icons/io5";

const ViewCampaignVideos = () => {
  const videoDivRef = useRef(null);
  const { videoData, showVideos } = useSelector(
    (state) => state.showLinkedVideos
  );
  const dispatch = useDispatch();
  const handleClick = (event) => {
    if (videoDivRef.current && videoDivRef.current.contains(event.target)) {
      dispatch(showVideo());
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

 useEffect(()=>{
  if(showVideos){
    document.body.style.overflow = "hidden"
  }else{
    document.body.style.overflow = "auto"
  }
 },[showVideos])
  return (
    <>
      {showVideos && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
          }}
          className="show-videos-container"
          ref={videoDivRef}
        >
          <div onClick={(e) => e.stopPropagation()} className="videos-wrpper">
            <div className="linked-video-header">
              <h3>Linked Videos</h3>
              <IoCloseCircle onClick={()=>dispatch(showVideo())}/>
            </div>
     
            {videoData?.map((val, i) => (
              <div className="linked-video-container">
                <div key={i} className="linked-video-img">
                  <img src={val?.videoThumbnail} alt="" srcset="" />
                </div>
                <div className="linked-video-info">
                    <h3 className="linked-video-title">{val?.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </>
  );
};

export default ViewCampaignVideos;
