export const numberFormatter = (number=0) => {
    const typeNumber = Number(number);
  
    if (isNaN(typeNumber)) {
      return "Invalid Number";
    }
  
    if (typeNumber < 1000) {
      return typeNumber.toString();
    }
  
    if (typeNumber < 1000000) {
      return (typeNumber / 1000).toFixed(2) + "K";
    }
  
    return (typeNumber / 1000000).toFixed(1) + "M";
  };
  