import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import authAxios from "../../config/authaxios"


const initialState = { status: null, message: "", loading: false, data: [], error: "" }

export const getSinglePaymentTransactionAction = createAsyncThunk(
    'single-payment-tran',
    async (data, { rejectWithValue }) => {
        try {
            const res = await authAxios.get(`/single/payment/trasaction?transactionId=${data?.transactionId}`,)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getSinglePaymentTransactionSlice = createSlice({
    name: 'single-payment-tran',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getSinglePaymentTransactionAction.pending, (state) => {
                state.data = []
                state.loading = true
            })
            builder.addCase(getSinglePaymentTransactionAction.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.loading = false
                state.message = action.payload.message
                state.status = action.payload.status
            })
            builder.addCase(getSinglePaymentTransactionAction.rejected, (state, action) => {
            
                state.loading = false
                state.error = action?.payload?.error
                state.message = action.payload?.message
                state.status = action.payload?.status
            })
        },

})



export const getSinglePaymentTransactionReducer = getSinglePaymentTransactionSlice.reducer