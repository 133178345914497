import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getUserSpentAction } from "../../redux/payment/getUserSpents";
import Loader from "../../components/Loader";
import { Box, Button } from "@mui/material";
import { IoRefreshCircleSharp } from "react-icons/io5";
import PaymentSvg from "../../assets/payments.svg";
import { DataGrid } from "@mui/x-data-grid";
import '../../css/add-fund/payment.scss'
import MobileViewTransaction from "./MobileViewTransaction";
const Payment = () => {
  const [windowSize,setWindowSize] = useState(window.screen.availWidth)
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.userSpent);
  useEffect(() => {
    dispatch(getUserSpentAction());
  }, []);

  const handleWindowResize = ()=>{
setWindowSize(window.screen.availWidth)
  }
  useEffect(()=>{
    window.addEventListener("resize",handleWindowResize)
    return ()=>{
      window.removeEventListener("resize",handleWindowResize)
    }
  },[])
  console.log(windowSize)
  const column = [
    {
      field: "campaignId",
      headerName: "CAMPAIGN ID",
      flex: 1,
      minWidth:100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "campaignAmount",
      headerName: "CAMPAIGN AMT.",
      headerClassName: "super-app-theme--header",
      minWidth:140,
    },
    {
      field: "walletDeductedAmount",
      headerName: "WALLET DED.",
      headerClassName: "super-app-theme--header",
      minWidth:140,
    },
    {
      field: "amount",
      headerName: "AMOUNT PAID",
      flex: 1,
      minWidth: 120,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "merchantTransactionId",
      headerName: "TRANSACTION ID",
      flex: 1,
      minWidth: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1,
      minWidth:140,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "createdAt",
      headerName: "TIME",
      flex: 1,
      minWidth: 310,
      headerClassName: "super-app-theme--header",
    },
  ];
  return (
    <>
      <motion.div
        className="body-container"
        initial={{
          opacity: 0,
          transform: "scale(0.9)",
        }}
        animate={{
          opacity: 1,
          transform: "scale(1)",
        }}
        transition={{
          duration: 0.2,
        }}
      >
        <Box
          className = "payment-spends-header"
        >
          <img
            src={PaymentSvg}
            alt="campaign"
            style={{
              width: "100px",
            }}
          />
          <h3>Payment Spends</h3>
          <Button
          className="payment-spend-refresh-btn"
          onClick={()=>window.location.reload()}
            startIcon={
              <IoRefreshCircleSharp
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
          >
            {" "}
            Refresh
          </Button>
        </Box>
        <Box
          sx={{
            background: "white",
            minHeight: "70vh",
            marginTop: "-5px",
            paddingBlock: "5px",
            borderRadius: "0px 0px 10px 10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "95%",
              paddingBlock: "20px",
              bgcolor: "#fff",
              "& .super-app-theme--header": {
                bgcolor: "#071D45",
                color: "#fff",
              },
            }}
          >

            {windowSize > 500 ?  <DataGrid
              columns={column}
              rows={data}
              autoHeight
              getRowId={(row) => row?._id}
            /> :  <MobileViewTransaction data={data}/> }
         
          </Box>
        </Box>
      </motion.div>
      {loading && <Loader />}
    </>
  );
};

export default Payment;
