import React from "react";
import "./style.scss";
import { motion } from "framer-motion";
import { MdDelete } from "react-icons/md";

const VideoList = ({ data = [],handleRemoveVideos }) => {

  return (
    <>
    
        <motion.div initial={{opacity:0}} animate ={{opacity:1}} transition={{duration:0.3}} className="selected-videos-container">
          {data.map((val, i) => (
            <div className="show-selected-videos-preview">
              <div className="delete-btn" onClick={()=>handleRemoveVideos(val.videoId)}>
              <MdDelete />
              </div>
             
              <img src={val.thumbnail} alt="" srcset="" />
              <h3>{val.title}</h3>
            </div>
          ))}
        </motion.div>
  
    </>
  );
};

export default VideoList;
