import React, { useEffect, useState } from "react";
import "../../css/set-campaign/singlevideo.scss";
import Country from "./Country";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import CryptoJS from "crypto-js";
import uniqid from "uniqid";
import { Toast } from "../../components/Toast";
import { saveVideoCampaignActon } from "../../redux/video-info/saveVideoCampaign";
import { MdDelete } from "react-icons/md";
import { resetCountry } from "../../redux/common/country";
import { BiSolidOffer } from "react-icons/bi";
import NoIdModal from '../../components/NoIdModel'
import { getAllPromoCodesAction } from "../../redux/meta/getAllPromoCodes";
import { IoMdCloseCircle } from "react-icons/io";

let message;
const SingleVideo = () => {
  // states
  const getMultipleVideos = JSON.parse(localStorage.getItem("multipleVideos"));
  const [multipleVideos, setMultipleVideos] = useState([...getMultipleVideos]);
  const [targetViews, setTargetViews] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const[promoCodeId,setPromoCodeId] = useState("");
  const [applyPromo, setApplyPromo] = useState(false);
  const [promotionLink, setPromotionLink] = useState("");
  const [snake, setSnake] = useState(false);
  const [budget, setBudget] = useState(null);
  const [extraFeatures, setExtraFeatures] = useState("");
  const secretKey = "yourSecretKey";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // redux
  const { loading: saveCampaignLoading } = useSelector(
    (state) => state.saveVideoCampaign
  );
  const { countryList } = useSelector((state) => state.countryList);
  const {data:promocodeData} = useSelector(state=>state.getPromoCode)

  // local storage
  const campignId = localStorage.getItem("campignId");
  const { pathname } = useLocation();

  useEffect(() => {
    if (!campignId) {
      localStorage.setItem("campignId", uniqid());
    }
    dispatch(resetCountry());
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  useEffect(()=>{
    dispatch(getAllPromoCodesAction())
  },[])

  const handleSubmitCampaign = async () => {
    const videoData = multipleVideos.map((val) => val.videoId);
    if (!budget) {
      message = "Please enter the budget !";
      setSnake(true);
      return;
    }
    if(!extraFeatures){
      message = "Please select the Extra Features";
      setSnake(true);
      return;
    }

    if(countryList?.length <=0){
      message = "Please select the targeted countries";
      setSnake(true);
      return;
    }

    const jsonString = JSON.stringify({
      budget: budget,
      targetViews: targetViews,
      extraFeatures: extraFeatures,
      campaignId: campignId || uniqid(),
      videoData: videoData,
      targetCountries: countryList,
      promotionLink: promotionLink || "",
      promoCode:promoCodeId
    });
    const ciphertext = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
    const saveVideoCampaign = await dispatch(
      saveVideoCampaignActon({ campaignData: ciphertext })
    );
    if (saveVideoCampaign?.payload?.status === 200) {
      localStorage.setItem(
        "paymentData",
        JSON.stringify({
          amount: budget,
          campignId: campignId,
          views: targetViews,
        })
      );
      return navigate(`/make-payment/${saveVideoCampaign?.payload?.data}`);
    }
    message = saveVideoCampaign?.payload?.message;
    setSnake(true)
  };
  const features = [
    "Subscribers",
    "Likes & Comments",
    "Website Traffic",
    "Social Fans",
    "App Installs",
  ];


  const handlPromotionLink = (e) => {
    setPromotionLink(e.target.value);
  };
  useEffect(() => {
    if (budget > 0) {
      setTargetViews(budget * 50);
    } else {
      setTargetViews(0);
    }
  }, [budget]);

  const removeVideos = (videoId) => {
    if (multipleVideos.length <= 1) {
      return;
    }
    setMultipleVideos(() =>
      multipleVideos.filter((val) => val.videoId !== videoId)
    );
  };
  const HandleApplyPromo = () => {
    if (!promoCode) {
      return;
    }
    setApplyPromo(!applyPromo);
  };

  const placeHolder = ()=>{
    if(extraFeatures.includes("Website Traffic")){
      return "Enter Website Link"
    }
    if (extraFeatures.includes("Social Fan")){
      return "Enter  Social Fan Link"
    }
    if (extraFeatures.includes("App Install")){
      return "Enter App Link"
    }
  }

  if(!getMultipleVideos){
    return (
      <NoIdModal
        header={"Error"}
        content={"Something went wrong. Please try again after sometime."}
      />
    )
  }

  return (
    <>
      {saveCampaignLoading ? (
        <Loader />
      ) : (
        <div className="campaign-video">
          <div className="show-yt-video-thumbnail">
            {multipleVideos &&
              multipleVideos?.map((val) => (
                <div className="multiple-videos-div">
                  <img src={val.thumbnail} alt="" />
                  <p>{val.title}</p>
                  <MdDelete
                    style={{
                      fontSize: "2rem",
                      cursor: "pointer",
                    }}
                    onClick={() => removeVideos(val.videoId)}
                  />
                </div>
              ))}
            <div className="count-view-div">
              <h3>Estimated Views</h3>
              <h4>
                {targetViews === 0
                  ? 0
                  : `${targetViews} - ${Number(targetViews + 11 * budget)} `}
              </h4>
            </div>
          </div>

          <div className="campaign-setting">
            <div className="enter-amount-for-campaign">
              <h3>Enter your Budget</h3>
              <input
                type="number"
                placeholder="Amount"
                value={budget}
                className="amout-input"
                onChange={(e) => setBudget(e.target.value)}
              />
            </div>

            {
          applyPromo &&   <div className="apply-promo-div">
          <BiSolidOffer
            style={{
              position: "absolute",
              left: "25px",
              top: "13px",
              fontSize: "1.4rem",
              opacity: 0.3,
            }}
          />
         
          <input
          disabled
            type="text"
            className="country-input"
            placeholder=" Apply Promo"
            value={"Applied" + " " + promoCode?.toUpperCase()}
            style={{
              border:"none",
              backgroundImage:
                applyPromo &&
                "linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%)",
            }}
          />
          <IoMdCloseCircle onClick={HandleApplyPromo} style={{
            fontSize:"2.5rem",
            cursor:"pointer"
          }}/>
     
         
        </div>
        }
            {promocodeData.map((val, i) => (
            <div className="offer-promo-div">
              <h4>{val?.offerName === "first50" ? "Get 50% Cashback  only for new users.": val?.offerName === "vip50" ? "Get 50% Cashback on amount above ₹5,000" : "Get 100% Cashback on amount above ₹10,000" }</h4>
              <button className="btn-primary" onClick={()=>{setPromoCode(val.offerName); setPromoCodeId(val._id); setApplyPromo(true)}} > {val.offerName?.toUpperCase()}</button>
            </div>
          ))}
            <br />
            <br />
            <h3>Target by country</h3>
            <Country />
            <br />
            <div className="apart-from-view">
              <h3>What do you want besides views ?</h3>
              <div className="promotion-optons-div">
                {features.map((val, i) => (
                  <button
                    style={{
                      background: extraFeatures === val ? "red" : "black",
                    }}
                    className="btn-primary"
                    onClick={() => setExtraFeatures(val)}
                    key={i}
                  >
                    {val}
                  </button>
                ))}
              </div>
              {extraFeatures.includes("Website Traffic") ||
              extraFeatures.includes("Social Fan") ||
              extraFeatures.includes("App Install") ? (
                <div className="promotion-link">
                  <input type="text" placeholder={placeHolder()} onChange={handlPromotionLink} />
                </div>
              ) : (
                ""
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <button
                  className="btn-secondary"
                  onClick={handleSubmitCampaign}
                >
                     Promote Channel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Toast open={snake} setOpen={setSnake} message={message} />
    </>
  );
};

export default SingleVideo;
