import React, { useEffect, useState } from "react";
import AddUrlInput from "../promote-video/AddUrlInput";
import "../../css/select-video/selectvideo.scss";
import { FcStart } from "react-icons/fc";
import { useNavigate} from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";
import { IoIosRemoveCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getChannelVideoAction } from "../../redux/channel/getChannelVideos";
import Loader from "../../components/Loader";
import NoIdModal from "../../components/NoIdModel";
import { getChannelByIdAction } from "../../redux/channel/getChannelById";
import VideoList from "./showSelectedVideo/VideoList";

const SelectVideo = () => {
  const [videoId, setVideoId] = useState([]);
  const[title,setTitle] = useState("")
  const[multipleVideos, setMultipleVideos] = useState([])
  const {data,loading ,status} = useSelector((state) => state.getChannelVideo);
  const  channelId  = localStorage.getItem("channelId")
  const {data:channelInfo} = useSelector(state=>state.getChannel)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (channelId) {
      dispatch(getChannelVideoAction({ channelId: channelId ,title}));
      dispatch(getChannelByIdAction(channelId))
    }
  }, []);

  if(!channelId){
    return (
      <NoIdModal
        header={"Error"}
        content={"Something went wrong. Please try again after sometime."}
      />
    )
  }
  if (status && status !==200) {
    return (
      <NoIdModal
        header={"Server Error"}
        content={"Something went wrong. Please try again after sometime."}
      />
    );
  }
  
  const addVideo = (id) => {
    if (videoId.includes(id)) {
      return;
    }
  const selecedtVideos = data?.videos?.filter((val) =>
    val.videoId === id
  );

  setMultipleVideos([...multipleVideos,selecedtVideos].flat())
  setVideoId([...videoId, id]);
  };

  const removeVideo = (val) => {
    setMultipleVideos(multipleVideos.filter((video)=>video.videoId !== val));
    setVideoId(videoId.filter((video) => video !== val));
  };

const hanldeInputChange = (e)=>{
  setTitle(e.target.value)

}

const handleKeyDown = (e)=>{
  if(!channelId){
    return
  }
if(e.key === "Enter"){
  dispatch(getChannelVideoAction({ channelId: channelId ,title:title}));
}
}

  const handleNext = () => {
    if (videoId.length > 0) {
      localStorage.setItem("multipleVideos", JSON.stringify(multipleVideos));
      navigate("/save-multiple-video-campaign");
    } else return;
  };

  const handleSearch = ()=>{
    if(!channelId){
      return
    }
    dispatch(getChannelVideoAction({ channelId: channelId ,title:title}));
  }

  return (
    <>
      <div className="body-container">
        <div className="select-video-container">
          <div className="channel">
            <div className="channel-img">
              <img
                src={channelInfo.profileImage?.url}
                alt="channelProfile"
                style={{
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
            <p>{channelInfo?.channelName}</p>
            <FcStart />
          </div>
          <br />
          <AddUrlInput onClick={handleSearch} placeholder={"Search By Title"}  onKeyDown={handleKeyDown} onChange={hanldeInputChange} />
          {multipleVideos.length > 0  && <VideoList handleRemoveVideos={removeVideo} data={multipleVideos}/>}
          <br />
          <div className="show-channel-video">
            {data?.videos?.map((val, i) => (
              <div
                style={{
                  background: videoId.includes(val.videoId) ? "#ececee" : "",
                }}
                className="show-video"
                key={i}
              >
                <div
                  className="add-video"
                  onClick={
                    videoId.includes(val.videoId)
                      ? () => removeVideo(val.videoId)
                      : () => addVideo(val.videoId)
                  }
                >
                 
                  {videoId.includes(val.videoId) ? <IoIosRemoveCircle style={{color:"red"}}/> :  <IoIosAddCircle/>}
                </div>
                <img
                  style={{
                    width: "100px",
                    height: "60px",
                    borderRadius: "12px",
                  }}
                  src={val?.thumbnail}
                  alt=""
                  srcset=""
                />
                <p>{val.title}</p>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <button className="btn-secondary" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
      {loading && <Loader />}
     
    </>
  );
};

export default SelectVideo;
