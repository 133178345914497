import React from 'react'
import '../../css/add-fund/mobiletransaction.scss'
import { SlGraph } from "react-icons/sl";
import { IoIosTime } from "react-icons/io";
import { BiSolidShield } from "react-icons/bi";
import NodataImage from '../../assets/nodata.svg'
import { FaEye } from "react-icons/fa";
import { IoMdWallet } from "react-icons/io";

const MobileViewTransaction = ({data}) => {
  if(data.length === 0){
    return (
      <div style={{
        width:"100%",
        background:"whitesmoke",
        textAlign:"center",
        paddingBlock:"10px",
        boxShadow:"0 3px 16px rgba(110, 125, 177, 0.28)",
        borderRadius:"5px"
      }}>
        <h3>Sorry ! No Data Available</h3>
        <img src={NodataImage} alt="" srcset="" />
         </div>
    )
  }
  return (
    <>
    {
        data?.map((val,i)=><div key={i} className='mobile-user-trans-div'>
           <div
              style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                background:val?.status === "Under View" ? "green" : val?.status === "Pending" ? "red": val?.status === "In Progress" ? "#D35400 " : "purple",
                paddingInline:"3px",
                paddingBlock:"1px",
                borderRadius:"5px",
                color:"white",
                overflow:"hidden"
              
              }}
            >
             <p style={{
                fontSize:"0.75rem",
                fontWeight:520
             }}> {val?.status}</p>
            </div>
        <div className="user-info-transaction">
        <SlGraph/>  <h3>Campaign Id :</h3>   <p>{val?.campaignId?.toUpperCase()}</p> 
        </div>
        <div className="user-info-transaction">
          <IoMdWallet/>  <h3>Campaign Amount:</h3> <p>₹ {val?.campaignAmount}</p>
        </div>
        <div className="user-info-transaction">
          <IoMdWallet/>  <h3>Amount Paid:</h3> <p>₹ {val?.amount}</p>
        </div>
        <div className="user-info-transaction">
          <IoMdWallet/>  <h3>Wallet Deduction :</h3> <p>₹ {val?.walletDeductedAmount}</p>
        </div>
        <div className="user-info-transaction">
          <BiSolidShield/>  <h3>TD:</h3> <p>{val?.merchantTransactionId}</p>
        </div>
        <div className="user-info-transaction">
        <FaEye/>  <h3>Campaign Status : </h3> <p>{val?.status}</p>
        </div>
        <div className="user-info-transaction">
        <IoIosTime/>  <h3>Time :</h3> <p>{val?.createdAt}</p> 
        </div>
    </div>)
    }
    </>
  )
}

export default MobileViewTransaction