import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authAxios from "../../config/authaxios";

const initialState = {
  status: null,
  message: "",
  loading: false,
  data: [],
  error: "",
};

export const addChannelAction = createAsyncThunk(
  "add-channel",
  async (data, { rejectWithValue }) => {
    try {
      const res = await authAxios.get(`/add-channel?channelId=${data}`);
      return res?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addChannelSlice = createSlice({
  name: "add-channel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addChannelAction.pending, (state) => {
      state.data = [];
      state.loading = true;
    });
    builder.addCase(addChannelAction.fulfilled, (state, action) => {
      state.data = action.payload.data;
      state.loading = false;
      state.message = action.payload.message;
      state.status = action.payload.status;
    });
    builder.addCase(addChannelAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload?.error;
      state.message = action.payload?.message;
      state.status = action.payload?.status;
    });
  },
});

export const addChannelReducer = addChannelSlice.reducer;
