import React, { useEffect, useState } from "react";
import CampaignCard from "./CampaignCard";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { getAllCampaignAction } from "../../redux/campaign/getAllCampaign";
import Loader from "../../components/Loader";
import nodataImage from "../../assets/nodata.svg";
import Alert from "@mui/material/Alert";
import { Box, Button } from "@mui/material";
import { IoRefreshCircleSharp } from "react-icons/io5";
import CampaignSvg from "../../assets/campaign.svg";
import ViewCampaignVideos from "./ViewCampaignVideos";
import Modal from "../../components/Modal";
import { MdVerified } from "react-icons/md";
import { getSinglePaymentTransactionAction } from "../../redux/payment/getSingleTransaction";
import { MdError } from "react-icons/md";

let intervalId;
const Campaign = () => {
  const [rowsNumber, setRowsNumber] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const[openPaymentModal,setOpenPaymentModal] = useState(false)
  const [paymentStatus,setPaymentStatus] = useState("")
  const { data, loading } = useSelector((state) => state.allCampaign);
  const transactionId = localStorage.getItem("transactionId")
  const dispatch = useDispatch();
  useEffect(() => {
    window.scroll(0,0)
    dispatch(getAllCampaignAction({ limit: rowsNumber, page: currentPage }));
  }, [rowsNumber, currentPage]);

  useEffect(() => {
    if (data?.campaign) {
      setList(data?.campaign);
    }
  }, [data]);
 
  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        if (transactionId) {
          const getPaymentStatus = await dispatch(getSinglePaymentTransactionAction({ transactionId }));
          if (getPaymentStatus?.payload?.status === 200) {
            localStorage.removeItem("transactionId")
            setOpenPaymentModal(true);
            setPaymentStatus(getPaymentStatus?.payload?.data?.status);
          }
        }
      } catch (error) {
        console.error('Error fetching payment status:', error);
       
      }
    };

    fetchPaymentStatus(); 
  }, [transactionId]);
  
  useEffect(()=>{
    intervalId = setTimeout(()=>{setOpenPaymentModal(false)},6000)
    return () => {
      clearTimeout(intervalId);
    };
  },[transactionId])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <motion.div
          initial={{
            opacity: 0,
            transform: "scale(0.9)",
          }}
          animate={{
            opacity: 1,
            transform: "scale(1)",
          }}
          transition={{
            duration: 0.2,
          }}
          className="body-container"
        >
          <Box width="100%" className="my-campaign-header">
            <img
              className="campaign-img"
              src={CampaignSvg}
              alt="campaign"
              style={{
                width: "100px",
              }}
            />
            <Alert className="alert-campaign" severity="warning">
              Your campaign will go under review after you made a payment.
            </Alert>
            <Button
              onClick={() => window.location.reload()}
              className="campaign-refresh-btn"
              startIcon={
                <IoRefreshCircleSharp
                  style={{
                    fontSize: "1.5rem",
                  }}
                />
              }
            >
              {" "}
              Refresh
            </Button>
          </Box>
          <Box
            sx={{
              background: "white",
              minHeight: "70vh",
              marginTop: "-10px",
              paddingBlock: "20px",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            {list?.length === 0 ? (
              <div className="nodata-div-campaign">
                <h2>No Campaign Promoted</h2>
                <img className="nodata-image" alt="no-data" src={nodataImage} />
              </div>
            ) : (
              list.map((val, i) => (
                <CampaignCard
                  id={val._id}
                  targetViews={val.targetedViews}
                  paymentStatus={val.paymentStatus}
                  date={val.createdAt}
                  spentAmount={val.spentAmount}
                  views={val?.achievedViews}
                  status={val?.status}
                  campaignId={val?.campaignId}
                  budget={val.budget}
                  title={val?.videoInfo[0]?.title}
                  thumbnail={val?.videoInfo[0]?.videoThumbnail}
                  videoInfo={val.videoInfo}
                />
              ))
            )}
          </Box>

          {data?.campaign && data?.campaign.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                className="btn-primary"
                onClick={() => {
                  if (currentPage === 1) {
                    return;
                  }
                  setCurrentPage((prev) => prev - 1);
                }}
              >
                Prev
              </button>
              <button
                className="btn-primary"
                onClick={() => {if(data?.totalPages === currentPage){
                  return 
                }
                console.log(data?.totalPages < currentPage)
                  setCurrentPage((prev) => prev + 1)}}
              >
                Next
              </button>
            </div>
          )}
        </motion.div>
      )}
      <ViewCampaignVideos />
      <Modal open={openPaymentModal} setopen={setOpenPaymentModal} icon={paymentStatus === "Success" ? <MdVerified style={{color:"green", fontSize:"2rem"}}/> : <MdError style={{color:"red", fontSize:"2rem"}}/>} header={`Payment ${paymentStatus}`} content={paymentStatus === "Success" ? " Payment Recieved Successfully" : "Payment Not Received"}/>
    </>
  );
};

export default Campaign;
