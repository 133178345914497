import "./css/App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./screens/sidebar/Sidebar";
import PromoteVideo from "./screens/promote-video/PromoteVideo";
import PromoteChannel from "./screens/channel/PromoteChannel";
import Packages from "./screens/packages/Packages";
import Campaign from "./screens/campaign/Campaign";
// import PackageCampaign from "./screens/package-campaign/PackageCampaign";
import ChannelCamapign from "./screens/channel-campaign/ChannelCamapign";
import Report from "./screens/reports/Report";
import Payment from "./screens/payment/Payment";
import AddFunds from "./screens/add-payment/AddFunds";
import Ticket from "./screens/ticket/Ticket";
import Affiliate from "./screens/affiliate/Affiliate";
import StartPromotion from "./screens/start/StartPromotion";
import SelectVideo from "./screens/select-video/SelectVideo";
import SingleVideo from "./screens/set-campaign/SingleVideo";
import MonetizeChannel from "./screens/monetize-channel/MonetizeChannel";
import BuyMonetize from "./screens/monetize-channel/BuyMonetize";
import UpperHeader from "./components/UpperHeader";
import MultipleVideos from "./screens/set-campaign/MultpleVideos";
import Profile from "./screens/profile/Profile";
import Login from "./screens/auth/Login";
import VerifyEmail from "./screens/auth/VerifyEmail";
import ProtectedRoutes from "./components/ProtectedRoutes";
import PackageVideo from "./screens/set-campaign/PackageVideo";
import SelectSingleVideo from "./screens/select-video/SelectSingleVideo";
import MakePayment from "./screens/add-payment/MakePayment";
import { useEffect, useState } from "react";

function App() {
const [screenWidth, setScreenWidth] = useState(window.screen.availWidth);
 useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.screen.availWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  const isAuthenticated = !!localStorage.getItem("token");

  return (
    <div className="App">
      <Router>
        {isAuthenticated && screenWidth > 1000 ? <Sidebar /> : ""}
        {isAuthenticated ? <UpperHeader /> : ""}
        <Routes>
          <Route element={<ProtectedRoutes><PromoteVideo /></ProtectedRoutes>} path="/list-video" />
          <Route element={<ProtectedRoutes><PromoteChannel /></ProtectedRoutes> } path="/promote-channel" />
          <Route element={<ProtectedRoutes><Packages /></ProtectedRoutes> } path="/packages" />
          <Route element={<ProtectedRoutes><Campaign /></ProtectedRoutes> } path="/my-campaigns" />
          {/* <Route element={ <PackageCampaign />} path="/my-packages" /> */}
          <Route element={<ProtectedRoutes><ChannelCamapign /></ProtectedRoutes>} path="/my-channels" />
          <Route element={<ProtectedRoutes><Report /></ProtectedRoutes>} path="/reports" />
          <Route element={<ProtectedRoutes><Payment /></ProtectedRoutes>} path="/payments" />
          <Route element={<ProtectedRoutes><AddFunds /></ProtectedRoutes>} path="/add-funds" />
          <Route element={<ProtectedRoutes><Ticket /></ProtectedRoutes>} path="/tickets" />
          <Route element={<ProtectedRoutes><Affiliate /></ProtectedRoutes>} path="/affiliate" />
          <Route element={<ProtectedRoutes><StartPromotion /></ProtectedRoutes>} path="/start/" />
          <Route element={<ProtectedRoutes><SingleVideo /></ProtectedRoutes>} path="/save-single-video-campaign" />
          <Route
            element={<ProtectedRoutes><MultipleVideos /></ProtectedRoutes>}
            path="/save-multiple-video-campaign"
          />
          <Route
            element={<ProtectedRoutes><PackageVideo /></ProtectedRoutes>}
            path="/save-package-video-campaign"
          />
          <Route element={<ProtectedRoutes><SelectVideo /></ProtectedRoutes>} path="/select-videos" />
          <Route element={<ProtectedRoutes><SelectSingleVideo /></ProtectedRoutes>} path="/select-single-video" />
          <Route element={<MonetizeChannel />} path="/channel-monetize" />
          <Route
            element={<ProtectedRoutes><BuyMonetize /></ProtectedRoutes>}
            path="/buy-channel-monetize-package"
          />
          <Route
            element={<ProtectedRoutes><MakePayment /></ProtectedRoutes>}
            path="/make-payment/:campaignId"
          />
          <Route element={<ProtectedRoutes><Profile /></ProtectedRoutes>} path="/my-profile" />

          <Route element={<VerifyEmail />} path="/verify-email/:token" />
          <Route element={<Login isAuthenticated={isAuthenticated} />} path="/" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
