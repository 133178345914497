import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "../../css/add-fund/makepayment.scss";
import PhonePe from "../../assets/phonepe.webp";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TransparentLoader from "../../components/TransparentLoader";
import { Toast } from "../../components/Toast";
import CryptoJS from "crypto-js";
import { makePaymentAction } from "../../redux/payment/makePayment";
import { myProfileAction } from "../../redux/auth/myProfile";
import Modal from "../../components/Modal";
import { getSinglePaymentTransactionAction } from "../../redux/payment/getSingleTransaction";
import { MdError } from "react-icons/md";
import { MdVerified } from "react-icons/md";

const secretKey = "yourSecretKey";
const MakePayment = () => {
  const { campaignId } = useParams();
  // states
  const [snake, setSnake] = useState(false);
  const[openPaymentModal,setOpenPaymentModal] = useState(false)
  const [paymentStatus,setPaymentStatus] = useState("")
  const [flagPaymentPop,setFlagPaymentPop] = useState(true);

  // local storage
  const transactionId = localStorage.getItem("transactionId")
  const paymentData = JSON.parse(localStorage.getItem("paymentData"));

  // redux
  const dispatch = useDispatch();
  const {data,loading:balanceLoading} = useSelector(state=>state.getProfile)
  const { loading, message, status } = useSelector(
    (state) => state.makePayment
  );

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        if (transactionId) {
          const getPaymentStatus = await dispatch(getSinglePaymentTransactionAction({ transactionId }));
          if (getPaymentStatus?.payload?.status === 200) {
            localStorage.removeItem("transactionId")
            setOpenPaymentModal(true);
            setPaymentStatus(getPaymentStatus?.payload?.data?.status);
          }
        }
      } catch (error) {
        console.error('Error fetching payment status:', error);
       
      }
    };

    if(transactionId && flagPaymentPop){
      fetchPaymentStatus(); 
    }
  }, [transactionId,flagPaymentPop]);
  


  // prevent from going back
  const handlePopState = () => {
    window.location.replace(window.location.href);
  }

  useEffect(()=>{
    window.addEventListener("popstate",handlePopState)
    return ()=>window.removeEventListener("popstate",handlePopState)
  },[])


  const handleMakePayment = async () => {
    setFlagPaymentPop(false);
    const jsonString = JSON.stringify({
      campaignId: campaignId,
    });
    const ciphertext = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
    const payment = await dispatch(
      makePaymentAction({ paymentData: ciphertext })
    );
    setSnake(true);
    if (
      payment?.payload?.status === 200 &&
      payment?.payload?.type === "wallet"
    ) {
      localStorage.setItem("transactionId",payment?.payload?.transactionId)
      localStorage.removeItem("campignId");
      window.location.href = "/my-campaigns";
    }
    if (
      payment?.payload?.status === 200 &&
      payment?.payload?.type === "paymentGateway"
    ) {
     
      localStorage.removeItem("campignId");
      localStorage.setItem("transactionId",payment?.payload?.transactionId)
      localStorage.setItem("paymentCompleted",false)
      window.open(
        payment?.payload?.data?.data?.instrumentResponse?.redirectInfo?.url,
        "_self"
      );
    }
  };

  useEffect(()=>{
    dispatch(myProfileAction())
  },[flagPaymentPop])

  return (
    <>
      <div className="make-payment-body ">
        <motion.div
          initial={{
            x: -50,
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 0.3,
          }}
          className="payment-selected-channel"
        >
          <div className="campaign-id">
            <h4>Campaign Id</h4>
            <p>{paymentData?.campignId.toUpperCase()}</p>
          </div>
          <div className="assured-view">
            <p>Assured Views {flagPaymentPop ? "true" : "false"}</p>
            <h4>{paymentData?.views}</h4>
          </div>
        </motion.div>
        <motion.div
          initial={{
            x: 50,
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            duration: 0.3,
          }}
          className="make-payment-container"
        >
          <h3>Bill Summary</h3>

          <div className="payment-container-form">
          <div className="make-payment-input-div dot-border">
              <p>Available Balance  </p>
              <span>₹{data?.balance}</span>
            </div>

          <div className="make-payment-input-div">
              <p>Campaign Budget</p>
              <span>₹{paymentData?.amount}</span>
            </div>
            <div className="make-payment-input-div dot-border">
              <p>Deducted From Wallet</p>
              <span> - ₹{paymentData?.amount > data?.balance ? data?.balance : paymentData?.amount}</span>
            </div>

            <div className="make-payment-input-div">
              <p>Amount To Be Paid</p>
              <span>₹{paymentData.amount >  data?.balance ? paymentData.amount - data?.balance : 0}</span>
            </div>

            <div className="make-payment-input-div">
              <h4>Pay Via</h4>
              <img
                style={{
                  width: "30px",
                }}
                src={PhonePe}
                alt=""
              />
            </div>

            <button
              style={{
                width: "100%",
              }}
              className="btn-primary"
              onClick={handleMakePayment}
            >
              Pay Now
            </button>
            <p
              style={{
                fontSize: 13,
                opacity: 0.5,
              }}
            >
              Powered By PhonePe
            </p>
          </div>
        </motion.div>
      </div>
      {loading && <TransparentLoader />}
      {balanceLoading && <TransparentLoader/>}
      <Modal open={openPaymentModal} setopen={setOpenPaymentModal} icon={paymentStatus === "Success" ? <MdVerified style={{color:"green", fontSize:"2rem"}}/> : <MdError style={{color:"red", fontSize:"2rem"}}/>} header={`Payment ${paymentStatus}`} content={paymentStatus === "Success" ? " Recieved Successfully" : "Payment Not Received"}/>
      <Toast
        open={snake}
        setOpen={setSnake}
        message={message}
        status={status}
      />
    </>
  );
};

export default MakePayment;
